import React from 'react';
import { Box, Button, Divider, styled } from '@mui/material';
import { useExtendedGeneration } from '../../../../context/ExtendedGenerationContext/UseExtendedGeneration';
import { InputCategory } from '../../../../types/enums';
import CheckboxField from '../../../../components/CheckboxField';
import InfluenceImageBlock from '../InfluenceImageBlock';
import InputField from '../../../../components/InputField';
import { AppLabels } from '../../../../utils/constants';


const ExtendedGenerateForm = (): JSX.Element => {

  const {
    text,
    setText,
  
    preserveCarShape,
    setPreserveCarShape,
    cameraViewFromControlImage,
    setCameraViewFromControlImage,
  
    mainImage,
    setMainImage,
  
    influenceImage1,
    setInfluenceImage1,
    weight1,
    setWeight1,
  
    influenceImage2,
    setInfluenceImage2,
    weight2,
    setWeight2,
  
    influenceImage3,
    setInfluenceImage3,
    weight3,
    setWeight3,
  
    influenceImage4,
    setInfluenceImage4,
    weight4,
    setWeight4,
  
    influenceImage5,
    setInfluenceImage5,
    weight5,
    setWeight5,
  
    submitGeneration,
    readyToGenerate,
  } = useExtendedGeneration();

  const onTextInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setText(event.target.value);
  };

  const onControlImageInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.files?.length ? event.target.files[0]: null;
    if(value) {
      setMainImage(value);
    }
  };

  const startGeneration = (): void => {
    if(readyToGenerate) {
      submitGeneration();
    }
  };

  return (
    <>
      <Wrapper>
        <InputField
          id="text"
          name="text"
          isRequired={false}
          isMultiline={false}
          value={text}
          category={InputCategory.Primary}
          onChange={onTextInputChange}
          label={AppLabels.extendedFormLabels.text}
        />
        <SubmitButton
          disabled={!readyToGenerate}
          onClick={startGeneration}
          variant="contained">
          {AppLabels.extendedFormLabels.submit}
        </SubmitButton>

        <FormWrapper>
          <ImageBlockWrapper>
            <UploadButton component="label" variant="contained" >
              {AppLabels.extendedFormLabels.controlImage}
              <input type="file"
                onChange={onControlImageInputChange}
                hidden />
            </UploadButton>
            { mainImage && <Image src={URL.createObjectURL(mainImage)} />}

            <CheckboxSectionWrapper>
              <CheckboxField
                id={'preserveCarShape'}
                label={AppLabels.extendedFormLabels.preserveCarShape}
                checked={preserveCarShape}
                onChange={setPreserveCarShape}
              />
              <CheckboxField
                id={'setCameraView'}
                label={AppLabels.extendedFormLabels.setCameraView}
                checked={cameraViewFromControlImage}
                onChange={setCameraViewFromControlImage}
              />
            </CheckboxSectionWrapper>
          </ImageBlockWrapper>

          <ContentDivider />

          <InfluenceImageBlock
            id={'influenceImage1'}
            label={AppLabels.extendedFormLabels.influence1}
            currentImage={influenceImage1}
            setCurrentImage={setInfluenceImage1}
            weight={weight1}
            setWeight={setWeight1}
          />

          <ContentDivider />

          <InfluenceImageBlock
            id={'influenceImage2'}
            label={AppLabels.extendedFormLabels.influence2}
            currentImage={influenceImage2}
            setCurrentImage={setInfluenceImage2}
            weight={weight2}
            setWeight={setWeight2}
          />

          <ContentDivider />

          <InfluenceImageBlock
            id={'influenceImage3'}
            label={AppLabels.extendedFormLabels.influence3}
            currentImage={influenceImage3}
            setCurrentImage={setInfluenceImage3}
            weight={weight3}
            setWeight={setWeight3}
          />

          <ContentDivider />

          <InfluenceImageBlock
            id={'influenceImage4'}
            label={AppLabels.extendedFormLabels.influence4}
            currentImage={influenceImage4}
            setCurrentImage={setInfluenceImage4}
            weight={weight4}
            setWeight={setWeight4}
          />

          <ContentDivider />

          <InfluenceImageBlock
            id={'influenceImage5'}
            label={AppLabels.extendedFormLabels.influence5}
            currentImage={influenceImage5}
            setCurrentImage={setInfluenceImage5}
            weight={weight5}
            setWeight={setWeight5}
          />
        </FormWrapper>
      </Wrapper>
    </>
  );
};

export default ExtendedGenerateForm;

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  textAlign: 'center',
  alignItems: 'start',

  '& button.Mui-disabled': {
    color: theme.palette.neutrals.contrastText,
    backgroundColor: theme.palette.primary.light1,    
  }
}));
const FormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'start',

  width: '100%',

  border: `1px solid ${theme.palette.primary.inactive}`,
  borderRadius: '10px',
  padding: '15px 12px 10px 12px'
}));

const ContentDivider = styled(Divider)(({ theme }) => ({
  background: theme.palette.primary.inactive,

  width: '100%',
  height: '1px',
  margin: '20px 0px'
}));

const ImageBlockWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',

  height: '100%',
  width: '100%'
}));

const CheckboxSectionWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  marginTop: '20px',
  gap: '10px'
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  width: '60%',
  height: '50px',
  margin: '10px 0px',

  transition: 'all 0.5s',
  fontFamily: 'Roboto400',
  color: theme.palette.neutrals.contrastText,
}));

const UploadButton = styled(Button)<{component: string}>(({ theme }) => ({
  width: '60%',
  height: '50px',

  transition: 'all 0.5s',
  fontFamily: 'Roboto400',
  color: theme.palette.neutrals.contrastText,
}));

const Image = styled('img')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  maxWidth: '325px',
  height: '100%',

  borderRadius: '10px',
  margin: '5px 0'
}));