import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import routesConfig from './routesConfig';
import { useAuth } from '../context/AuthContext/UseAuth';
import { GuardType, UserRoles } from '../types/enums';

const GuardedRoute  = ({ guardType, children } : any): JSX.Element => {
    const { isSignedIn, userRole } = useAuth();

    let accessDenied = false;
    const shouldBeLoggedIn = guardType === GuardType.LoggedInOnly;
    const shouldBeAdmin = guardType === GuardType.AdminOnly;

    if(shouldBeLoggedIn) {
        accessDenied = !isSignedIn;
    }
    if(!accessDenied && shouldBeAdmin) {
        accessDenied = userRole !== UserRoles.Admin;
    }

    if (accessDenied) {
        return <Navigate to="/" replace />;
    }
    return children;
};

const generateRoute = (routes: any, element: any): JSX.Element => {
    return routes.map((route: any, index: any) => {
        return route.guard ? (
            <Route
                path={route.path}
                element={<GuardedRoute guardType={route.guard}>{route[element]}</GuardedRoute>}
                key={index}>
                {route.child && generateRoute(route.child, element)}
            </Route>
        ) : (
            <Route path={route.path} element={route[element]} key={index}>
                {route.child && generateRoute(route.child, element)}
            </Route>
        );
    });
};
const getNavItemObject = (parentRoutesArray: any, parentRouterName: any): any => {
    let childRoutes: any[] = [];

    parentRoutesArray.forEach((route: any) => {
        if (route.label === parentRouterName) {
            childRoutes = [...route.child];
        } else {
            if (route.child) {
                const child = getNavItemObject(route.child, parentRouterName);
                childRoutes = child.length > 0 ? [...child] : [...childRoutes];
            }
        }
    });

    return childRoutes;
};


export const mainRoutes = generateRoute(routesConfig, 'mainElement');
export const MainNav = getNavItemObject(routesConfig, 'MainNavigation');