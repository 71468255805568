import React, { PropsWithChildren, createContext, useContext, useState, useEffect } from 'react';
import { ErrorMessages } from '../../utils/constants';
import { useStyleDriveApi } from '../../hooks/api/useStyleDriveApi/useStyleDriveApi';
import { DEFAULT_SWITCH1, DEFAULT_SWITCH2, DEFAULT_WEIGHT2, GenerateImageConfig } from '../../types/styleDriveApi/styleDriveApiTypes';
import { useLoader } from '../LoaderContext/UseLoader';
import { generateSeed } from '../../utils/helpers';
import { ExtendedGenerationItems } from '../../types/contextTypes';
import { useLocalStorage } from '../LocalStorageContext/UseLocalStorage';
import { SupportedModels } from '../../types/enums';
import config from '../../config';

const ExtendedGenerationContext = createContext<ExtendedGenerationItems>({} as ExtendedGenerationItems);

export const ExtendedGenerationProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {

    const [text, setText] = useState<string>('');
    const [mainImage, setMainImage] = useState<File | null>(null);

    const [influenceImage1, setInfluenceImage1] = useState<File | null>(null);
    const [weight1, setWeight1] = useState<number>(100);

    const [influenceImage2, setInfluenceImage2] = useState<File | null>(null);
    const [weight2, setWeight2] = useState<number>(100);

    const [influenceImage3, setInfluenceImage3] = useState<File | null>(null);
    const [weight3, setWeight3] = useState<number>(100);

    const [influenceImage4, setInfluenceImage4] = useState<File | null>(null);
    const [weight4, setWeight4] = useState<number>(100);

    const [influenceImage5, setInfluenceImage5] = useState<File | null>(null);
    const [weight5, setWeight5] = useState<number>(100);

    const [preserveCarShape, setPreserveCarShape] = useState<boolean>(true);
    const [cameraViewFromControlImage, setCameraViewFromControlImage] = useState<boolean>(true);

    const [activeImageUrl, setActiveImageUrl] = useState('');
    const [readyToGenerate, setReadyToGenerate] = useState(true);

    const { generateImage } = useStyleDriveApi();
    const { showLoader, hideLoader} = useLoader();
    const { getImagesHistory, updateImagesHistory } = useLocalStorage();

    const [generationsHistory, setGenerationsHistory] = useState<string[]>([]);

    useEffect(() => {
        const currentHistory = getImagesHistory()
        setGenerationsHistory(currentHistory);
    }, []);

    useEffect(() => {
        setReadyToGenerate(!!mainImage);
    }, [mainImage,]);

    useEffect(() => {
        if(activeImageUrl?.length && !generationsHistory.includes(activeImageUrl)) {
            updateGenerationsHistory([activeImageUrl, ...generationsHistory]);
        }
    }, [activeImageUrl]);

    const updateGenerationsHistory = (historyItems: string[]): void => {
        updateImagesHistory(historyItems);
        setGenerationsHistory(historyItems);
    };


    const submitGeneration = async (): Promise<void> => {
        showLoader();
        const generationConfig: GenerateImageConfig = {
            text: text || '',
            seed: generateSeed(),
            inputImage1: mainImage,
            switch1: preserveCarShape, 
            switch2: cameraViewFromControlImage, 
            inputImage2: influenceImage1,
            weight2: weight1,
            inputImage3: influenceImage2,
            weight3: weight2,
            inputImage4: influenceImage3,
            weight4: weight3,
            inputImage5: influenceImage4,
            weight5: weight4,
            inputImage6: influenceImage5,
            weight6: weight5,
        };

        const result = await generateImage(generationConfig, SupportedModels.SD);
        hideLoader();
        const imageUrl = result ? `${config.baseUrl}${result.resultImage}` : '';
        setActiveImageUrl(imageUrl);
    };

    const clearForm = (): void => {
        setMainImage(null);

        setInfluenceImage1(null);
        setInfluenceImage2(null);
        setInfluenceImage3(null);
        setInfluenceImage4(null);
        setInfluenceImage5(null);

        setWeight1(DEFAULT_WEIGHT2);
        setWeight2(DEFAULT_WEIGHT2);
        setWeight3(DEFAULT_WEIGHT2);
        setWeight4(DEFAULT_WEIGHT2);
        setWeight5(DEFAULT_WEIGHT2);

        setActiveImageUrl('');

        setPreserveCarShape(DEFAULT_SWITCH1);
        setCameraViewFromControlImage(DEFAULT_SWITCH2);
    };

    const value: ExtendedGenerationItems = {
        mainImage,
        setMainImage,

        influenceImage1,
        setInfluenceImage1,
        weight1,
        setWeight1,

        influenceImage2,
        setInfluenceImage2,
        weight2,
        setWeight2,

        influenceImage3,
        setInfluenceImage3,
        weight3,
        setWeight3,

        influenceImage4,
        setInfluenceImage4,
        weight4,
        setWeight4,

        influenceImage5,
        setInfluenceImage5,
        weight5,
        setWeight5,

        activeImageUrl,
        setActiveImageUrl,

        text,
        setText,

        preserveCarShape,
        setPreserveCarShape,
        cameraViewFromControlImage,
        setCameraViewFromControlImage,

        clearForm,
        submitGeneration,
        readyToGenerate
    };

    return <ExtendedGenerationContext.Provider value={value}>{children}</ExtendedGenerationContext.Provider>;
};

export const useExtendedGeneration = (): ExtendedGenerationItems => {
    const context = useContext(ExtendedGenerationContext);
    if (!context) {
        throw new Error(ErrorMessages.context.extendedGenerationContextError);
    }
    return context;
}