import { Typography, styled } from '@mui/material';

export const PanelLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    color:  theme.palette.primary.inactive,

    '&.main': {
        textTransform: 'uppercase',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '22px',
        color:  theme.palette.primary.contrastText
    },

    '&.error': {
        textWrap: 'balance',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '22px',
        color:  theme.palette.primary.contrastText
    },

    '&.secondary': {
        color:  theme.palette.primary.contrastText
    }
}));
