import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const GalleryIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24" 
            {...props}>
                <rect x="3.75" y="1.75" width="18.5" height="18.5" rx="2.95588" fill="currentColor" stroke="white" strokeWidth="1.5"/>
                <rect x="0.75" y="4.75" width="18.5" height="18.5" rx="2.95588" fill="currentColor" stroke="white" strokeWidth="1.5"/>
                <circle cx="6.676" cy="10.676" r="1.9692" transform="rotate(0.147302 6.676 10.676)" fill="currentColor" stroke="white" strokeWidth="1.4"/>
                <path d="M14.0007 13.332L13.4957 12.8473C13.626 12.7116 13.8054 12.634 13.9935 12.6321C14.1816 12.6301 14.3626 12.704 14.4956 12.8371L14.0007 13.332ZM5.06732 16.4431L4.58265 15.9381C4.84111 15.6901 5.2451 15.6772 5.51883 15.9082L5.06732 16.4431ZM18.839 19.1603L13.5057 13.827L14.4956 12.8371L19.829 18.1704L18.839 19.1603ZM14.5056 13.8168L8.85728 19.7007L7.84732 18.7311L13.4957 12.8473L14.5056 13.8168ZM8.87384 19.6828L5.18886 23.7989L4.14579 22.8651L7.83077 18.749L8.87384 19.6828ZM7.90079 19.7508L4.61581 16.9781L5.51883 15.9082L8.80381 18.681L7.90079 19.7508ZM5.55199 16.9482L1.15199 21.1704L0.182653 20.1603L4.58265 15.9381L5.55199 16.9482Z" fill="white"/>
        </SvgIcon>
    );
};