import React from 'react'
import { useLoader } from '../../context/LoaderContext/UseLoader';
import { Box, styled } from '@mui/material';


const Loader = (): JSX.Element => {
    const { loaderState} = useLoader();
  return (
    <LoaderScreen state ={+loaderState}>
        <CustomLoader/>
    </LoaderScreen>
  );
};

const LoaderScreen = styled(Box)<{ state: number }>(({state, theme}) => ({
  width: '100vw',
  height: '100vh',
  position: 'absolute',
  top: 0,
  left: 0,
  display: !!state ? 'flex' : 'none',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.primary.contrastText,
  opacity: '70%',
  zIndex: '100',
}));

const CustomLoader = styled(Box)(({ theme }) => ({
  display: 'inline',
  margin: '100px auto',
  width: '60px',
  height: '60px',
  border: '5px solid rgba(255, 255, 255, 0.3)',
  borderRadius: '50%',
  borderTop: `5px solid ${theme.palette.neutrals.contrastText}`,
  animation: 'spin 1s linear infinite',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%' : {
      transform: 'rotate(360deg)'
    }
  }
}));
  

export default Loader;