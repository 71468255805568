import React, {PropsWithChildren, createContext, useContext, useState} from 'react';
import { DragSettings } from '../../types/contextTypes';
import { ErrorMessages } from '../../utils/constants';

const DragContext = createContext<DragSettings>({} as DragSettings);

export const DragProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
    const [draggedImage, setDraggedImage] = useState<File | null>(null);
    const value: DragSettings = {
        draggedImage,
        setDraggedImage
    };
    return <DragContext.Provider value={value}>{children}</DragContext.Provider>;
};

export const useDrag = (): DragSettings => {
    const context = useContext(DragContext);
    if (!context) {
        throw new Error(ErrorMessages.context.dragContextError);
    }
    return context;
}