import React, {PropsWithChildren, createContext, useContext} from 'react';
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse, Method} from 'axios';
import { RequestContextItems } from '../../../types/contextTypes';
import { ErrorMessages } from '../../../utils/constants';

const REACHING_LIMITS_ERROR_MESSAGE = '429 Too Many Requests';

const RequestContext = createContext<RequestContextItems>({} as RequestContextItems);

export const  RequestProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {


    const handleError = (error: AxiosError): void => {
        console.error(error);
    };

    const baseRequest = (url: string, method: Method, configs?: AxiosRequestConfig): Promise<AxiosResponse | null>  => {
        return axios({ url, method, ...configs})
            .then((response) => {
                if (response?.status >= 400 && response?.status != 401) {
                    handleError({
                        name: response.data?.errorCode,
                        message: response.data?.message
                    } as AxiosError);

                    if (response.data?.message.startsWith(REACHING_LIMITS_ERROR_MESSAGE)) {
                        return response;
                    }
                    return null;
                }
                return response;
            })
            .catch((error: AxiosError) => {
                handleError(error);
                return null;
            });
    };

    const requestGet = async (baseUrl: string, 
        endpoint?: string,
        params?: { [ key: string ]: any },
        headers?: { [ key: string ]: any }): Promise<AxiosResponse| null> => {
            const url = endpoint ? baseUrl.concat(endpoint) : baseUrl;
            const options = { params, headers };
            return baseRequest(url, 'get', options);
    };


    const requestPost = async (baseUrl: string, endpoint?: string, configs?: AxiosRequestConfig): Promise<AxiosResponse | null> => {
        const url = endpoint ? baseUrl.concat(endpoint) : baseUrl;
        return baseRequest(url, 'post', configs);
    };

    const requestPostWithJson = (baseUrl: string, endpoint: string, requestConfigs: AxiosRequestConfig): Promise<AxiosResponse | null> => {
        const url = endpoint ? baseUrl.concat(endpoint) : baseUrl;
        const configs: AxiosRequestConfig = {
            ...requestConfigs,
            headers: {
                ...requestConfigs.headers,
                'Content-Type': 'application/json',
            },
        };
        return baseRequest(url,'post', configs);
    };

    const requestPostWithForm = (baseUrl: string, endpoint: string, requestConfigs: AxiosRequestConfig): Promise<AxiosResponse | null> => {
        const url = endpoint ? baseUrl.concat(endpoint) : baseUrl;
        const configs: AxiosRequestConfig = {
            ...requestConfigs,
            headers: {
                ...requestConfigs.headers,
                'Content-Type': 'multipart/form-data',
            },
        };
        return baseRequest(url,'post', configs);
    };

    const requestPut = async(baseUrl: string, endpoint: string, configs: AxiosRequestConfig): Promise<AxiosResponse | null> => {
        const url = endpoint ? baseUrl.concat(endpoint) : baseUrl;
        return baseRequest(url, 'put', configs);
    };

    const requestPutWithJson = (baseUrl: string, endpoint: string, requestConfigs: AxiosRequestConfig = {}): Promise<AxiosResponse | null> => {
        const url = endpoint ? baseUrl.concat(endpoint) : baseUrl;
        const configs: AxiosRequestConfig = {
            ...requestConfigs,
            headers: {
                ...requestConfigs.headers,
                'Content-Type': 'application/json',
            },
        };
        return baseRequest(url, 'put', configs);
    };

    const requestPutWithForm = (baseUrl: string, endpoint: string, requestConfigs: AxiosRequestConfig = {}): Promise<AxiosResponse | null> => {
        const url = endpoint ? baseUrl.concat(endpoint) : baseUrl;
        const configs: AxiosRequestConfig = {
            ...requestConfigs,
            headers: {
                ...requestConfigs.headers,
                'Content-Type': 'multipart/form-data',
            },
        };

        return baseRequest(
            url,
            'put',
            configs
        );
    };
    
  
    const value = {
        requestGet,
        requestPost,
        requestPostWithJson,
        requestPostWithForm,
        requestPut,
        requestPutWithJson,
        requestPutWithForm
    };
    return <RequestContext.Provider value={value}>{children}</RequestContext.Provider>;
};


export const UseRequest = (): RequestContextItems => {
    const context = useContext(RequestContext);
    if (!context) {
        throw new Error(ErrorMessages.context.requestContext);
    }
    return context;
}