import { GenerateImageConfig, StyleDriveApi } from '../../../types/styleDriveApi/styleDriveApiTypes';
import config from '../../../config';
import { LocalStorageKeys } from '../../../utils/constants';
import { SupportedModels } from '../../../types/enums';
import { ImageWithMetadataDto } from '../../../types/dto';

export const useStyleDriveApi = (): StyleDriveApi => {
    const generateImage = async (imageConfig: GenerateImageConfig, generationType: string): Promise<ImageWithMetadataDto | null> => {
        const formData = new FormData();
        let result = null;

        for (const [key, value] of Object.entries(imageConfig)) {
            formData.append(key, value);
        }

        let path = '';
        switch (generationType) {
            case SupportedModels.PRECISE:
                path = config.path.generation.endpoint.generatePreciseImages;
                break;
            case SupportedModels.SDXL:
                path = config.path.generation.endpoint.generateSDXL;
                break;
            case SupportedModels.SD:
            default:
                path = config.path.generation.endpoint.generateImages;
                break;
        }

        await fetch(`${config.apiUrl}/${config.path.generation.baseUrl}${path}`, {
            method: 'POST',
            body: formData,
            headers: {
                Accept: 'multipart/form-data',
                Authorization: `Bearer ${localStorage[LocalStorageKeys.ACCESS_TOKEN_KEY]}`,
            },
        }).then((response) => response.json())
        .then((data) => {
            result = data || null;
        });
        return result;
    };

    return {
        generateImage
    };
};
