import { Box, styled } from '@mui/material';
import UploadImage from '../../../../assets/images/UploadImage.png';

export const PanelMainSection = styled(Box)<{ backgroundUrl?: string}>(({ theme, backgroundUrl }) => ({
    height: '358px',
    width: '568px',

    '&.action': {
        justifyContent: 'flex-start',
        paddingTop: '97px',
        cursor: 'pointer'
    },

    '&.info': {
        paddingTop: 0,
        justifyContent: 'center',
        cursor: 'default'
    },

    '&.action, &.info': {
        border: `2px dashed ${theme.palette.primary.inactive1}`,
        backgroundImage: `url(${UploadImage})` ,
        backgroundSize: '46%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },

    '@media (max-width:500px)': {
        width: '320px',
        '&.action': {
            paddingTop: '93px',
            backgroundSize: '86%'
        }
    },

    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.neutrals.contrastText,

    borderRadius: '14px',
    gap: '12px',

    ...(backgroundUrl && {
        background: `linear-gradient(rgba(255,255,255,.8), rgba(255,255,255,.8)), url(${backgroundUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    })
}));
