import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const UploadImageIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 62 69" 
            {...props}>
            <path d="M31.0001 14.9444C34.4365 14.9444 37.2223 12.1587 37.2223 8.72222C37.2223 5.28578 34.4365 2.5 31.0001 2.5C27.5636 2.5 24.7778 5.28578 24.7778 8.72222C24.7778 12.1587 27.5636 14.9444 31.0001 14.9444Z" stroke="currentColor" strokeWidth="4.5"strokeLinecap="round" strokeLinejoin="round" fill='none'/>
            <path d="M3 58.4998L27.9511 14.1354M34.08 14.1354L40.0844 24.8376M52.7778 30.4998C40.7378 42.9443 21.2622 42.9443 9.22222 30.4998M59 58.4998L52.28 46.5532" stroke="currentColor" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
        </SvgIcon>
    );
};
