import config from '../../config';
import { DemoPageApiItems, GenerateCadvizConfig } from '../../types/apiTypes';
import { CadvizGenerationDto } from '../../types/dto';
import { UseRequest } from '../api/useRequest/UseRequest';

export const  useDemoPageApi = (): DemoPageApiItems => {

    const { requestPostWithForm } = UseRequest();

    const generateCadviz = async (imageConfig: GenerateCadvizConfig, isFullVersion: boolean, cancel: any): Promise<CadvizGenerationDto | null> => {
        const formData = new FormData();

        for (const [key, value] of Object.entries(imageConfig)) {
            formData.append(key, value);
        }

        const path = isFullVersion ? 
            config.path.generation.endpoint.generateCadvizFull :
            config.path.generation.endpoint.generateCadviz;

        const response = await requestPostWithForm(
            `${config.apiUrl}/${config.path.generation.baseUrl}`,
            path,
            {
                data: formData,
                signal: cancel
            }
        );

        if (response?.data?.errorCode) {
            return { 
                image: '',
                ...(!isFullVersion && { remainingGenerations: 0 })
            };
        }

        return response?.data;
    };

    return {
        generateCadviz
    };
};
