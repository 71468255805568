import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthPage from '../pages/AuthPage';
import MainPage from '../pages/MainPage';
import GeneratePage from '../pages/GeneratePage';
import Loader from '../components/Loader';
import { GuardType, NavigationPaths } from '../types/enums';
import ExtendedGeneratePage from '../pages/ExtendedGeneratePage';
import DemoPage from '../pages/DemoPage';
import { DemoPageProvider } from '../context/DemoPageContext/useDemoPage';

const UsersList = React.lazy(() => import('../pages/UsersList'));

const routesConfig = [
    {
        path: '*',
        mainElement: <Navigate to="/auth" replace />,
    },
    {
        path: NavigationPaths.Auth,
        mainElement: <AuthPage />,
    },
    {
        path: NavigationPaths.DemoStyleDrive,
        mainElement: <DemoPageProvider><DemoPage /></DemoPageProvider>,
    },
    {
        guard: GuardType.LoggedInOnly,
        mainElement: <MainPage />,
        label: 'MainNavigation',
        child: [
            {
                path: NavigationPaths.Generate,
                mainElement: <GeneratePage />,
            },
            {
                guard: GuardType.AdminOnly,
                path: NavigationPaths.Users,
                mainElement: <React.Suspense fallback={<Loader/>}> <UsersList /></React.Suspense>,
            },
            {
                guard: GuardType.AdminOnly,
                path: NavigationPaths.OldStyleDrive,
                mainElement:  <ExtendedGeneratePage />,
            }
        ],
    },
];

export default routesConfig;
