import React from 'react';
import { Box, styled } from '@mui/material';
import MainPageForm from '../pages/MainPageForm';
import GenerationResult from '../pages/GenerationResult';
import { useMainPage } from '../context/MainPageContext/UseMainPage';
import Gallery from '../pages/Gallery';


const WebBrowserLayout = (): JSX.Element => {
    const { showGallery } = useMainPage();
  return (
    <ContentWrapper>
        <SideBarWrapper>
            <MainPageForm isWebLayout={true}></MainPageForm>
        </SideBarWrapper>
        <GenerationResultWrapper>
            <GenerationResult/>
        </GenerationResultWrapper>
        { showGallery && 
            <GalleryWrapper>
                <Gallery/>
            </GalleryWrapper> 
        }
    </ContentWrapper>
  );
};

const ContentWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 67px)',
    flexDirection: 'row',
    backgroundColor: theme.palette.neutrals.light1,
}));

const SideBarWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.neutrals.main,
    minWidth: '300px',
    width: '20%',
    maxWidth: '500px'
}));

const GenerationResultWrapper = styled(Box)(() => ({
    flex: '1',
    flexDirection: 'column',
    display: 'flex',
    overflow: 'auto',
    padding: '20px',
    width: '80%',
}));

const GalleryWrapper = styled(Box)(({ theme }) => ({
    paddingTop: '5px',
    backgroundColor: theme.palette.neutrals.main,
    flexDirection: 'column',
    display: 'flex',
    overflow: 'auto',
    alignContent: 'flex-end',
    width: '20vw',
    height: '100%',
}));

export default WebBrowserLayout