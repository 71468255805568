import React from 'react';
import { Box, styled, Dialog } from '@mui/material';
import { FullSizeImageDialogProps } from '../../../types/propsTypes';

const FullSizeImageDialogMobile = ({
  open,
  handleClose,
  imageUrl,
  children
}: FullSizeImageDialogProps): React.JSX.Element => {
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}>
          <Overlay>
            <OverlayImageContainer>
              {children}
              <OverlayImage src={imageUrl}></OverlayImage>
            </OverlayImageContainer>
          </Overlay>
        </Dialog>
    </div>
  );
};

export default FullSizeImageDialogMobile;

const Overlay = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.light1,
  display: 'flex',
  position: 'relative',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  zIndex: '100',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '100%',
  maxHeight: '100%',
}));

const OverlayImageContainer = styled(Box)(() => ({
  transform: 'rotate(-90deg)',
  transformOrigin: 'center',
  objectFit: 'contain',
  '& div': {
    top: '50%',
    right: '-40px',
    flexDirection: 'column-reverse',
    backgroundColor: 'transparent',
   },
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   position: 'absolute',
   height: '100dvw',
   width: '80dvh'
}));

const OverlayImage = styled('img')(() => ({
  maxWidth: '100%',
  maxHeight: '100%',
  borderRadius: '5px',
  objectFit: 'cover',
}));
