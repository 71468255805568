import React from 'react';
import { Box, TextField, styled, Typography } from '@mui/material';
import { InputFieldProps } from './InputField.types';

const InputField = ({
    id,
    name,
    label,
    value,
    onChange,
    isRequired ,
    isMultiline,
    isDisabled,
    placeholder,
    helperText,
    maxLength,
    error,
    type,
    category,
    onKeyDown,
    defaultValue
}: InputFieldProps): JSX.Element => {

    const inputComponents = {
        primary: PrimaryInput,
        secondary: SecondaryInput
    };

    const CustomInput = category ? inputComponents[category] : inputComponents.primary;

    return (
        <Wrapper>
            { label && <InputLabel>{label}</InputLabel> }

            <InputWrapper>
                <CustomInput
                    id={id}
                    name={name}
                    required={isRequired}
                    multiline={isMultiline}
                    disabled={isDisabled}
                    variant="outlined"
                    value={value}
                    helperText={helperText}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    error={error}
                    type={type}
                    InputProps={{
                        inputProps: { maxLength: maxLength }
                    }}
                />
            </InputWrapper>
        </Wrapper>
    );
};

export default InputField;

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    width: '100%',
}));

const InputWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',

    width: '100%',
    minHeight: '40px',
}));

const InputLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    fontSize: '16px',
    lineHeight: '18px',

    marginBottom: '6px',
    color: theme.palette.neutrals.light,
}));

const PrimaryInput = styled(TextField)(({ theme }) => ({
    width: '100%',

    '& .MuiOutlinedInput-root': {
        color: theme.palette.neutrals.contrastText,
        borderColor: 'transparent',

        fontSize: '14px',
        fontFamily: 'Roboto400',

        width: '100%',
        minHeight: '40px',
        padding: '10px 14px 10px 13px',

        'fieldset': {
            borderColor: theme.palette.primary.inactive,
        },

        '&:hover fieldset': {
            borderColor: theme.palette.neutrals.contrastText,
        },

        '& input:-webkit-autofill': {
            WebkitTextFillColor: theme.palette.neutrals.contrastText,
            WebkitBoxShadow: `0 0 0 30px ${theme.palette.neutrals.main} inset`,
            caretColor: theme.palette.neutrals.contrastText,
            borderRadius: '0px'
        }
    },

    '& .MuiFormHelperText-root': {
        color: theme.palette.neutrals.light
    },

    '& .MuiFormHelperText-root.Mui-error': {
        color: theme.palette.error.main
    },

    '& input': {
        padding: '0',
    }
}));

const SecondaryInput = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        fontSize: '16px',
        fontFamily: 'Roboto400',

        minHeight: '30px',
        width: '100%',
        padding: '5px 11px',

        color: theme.palette.neutrals.contrastText,
        borderColor: 'transparent',
        borderRadius: '6px',

        'fieldset': {
            borderColor: theme.palette.primary.light1,
        },

        '&:hover fieldset': {
            borderColor: theme.palette.primary.light1,
        },

        '& input:-webkit-autofill': {
            WebkitTextFillColor: theme.palette.neutrals.contrastText,
            WebkitBoxShadow: `0 0 0 30px ${theme.palette.neutrals.main} inset`,
            caretColor: theme.palette.neutrals.contrastText,
            borderRadius: '0px'
        }
    },

    '& input': {
        padding: '0',
    },
}));