import React from 'react';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { MainTheme } from './assets/theme/MainTheme';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { DragProvider } from './context/DragContext/useDrag';
import { MainPageProvider } from './context/MainPageContext/UseMainPage';
import LoaderContextProvider from './context/LoaderContext/UseLoader';
import { MainPageFormProvider } from './context/MainPageFormContext/UseMainPageForm';
import Loader from './components/Loader';
import { AuthProvider } from './context/AuthContext/UseAuth';
import { mainRoutes } from './routes/routes';
import { RequestProvider } from './hooks/api/useRequest/UseRequest';
import { ExtendedGenerationProvider } from './context/ExtendedGenerationContext/UseExtendedGeneration';

export const App = (): JSX.Element => {

  return (
    <ThemeProvider theme={MainTheme}>
      <CssBaseline />
      <BrowserRouter>
        <RequestProvider>
          <DragProvider>
            <LoaderContextProvider>
              <AuthProvider>
                <MainPageProvider>
                  <MainPageFormProvider>
                    <ExtendedGenerationProvider>
                      <Routes>
                        <Route path="*">
                          {
                            mainRoutes
                          }
                        </Route>
                      </Routes>
                      <Loader/>
                    </ExtendedGenerationProvider>
                  </MainPageFormProvider>
                </MainPageProvider>
              </AuthProvider>
            </LoaderContextProvider>
          </DragProvider>
        </RequestProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

