import { ImageWithMetadataDto } from '../dto';

export interface StyleDriveApi {
    generateImage: (config: GenerateImageConfig, generationType: string) => Promise<ImageWithMetadataDto | null>;
}

export const DEFAULT_SEED = 1;
export const DEFAULT_SWITCH1 = true;
export const DEFAULT_SWITCH2 = true;
export const DEFAULT_WEIGHT2 = 100;

export interface GenerateImageConfig {
    text: string;
    seed: number;
    inputImage1: File | null;
    inputImage2: File | null;
    switch1: boolean;
    switch2: boolean;
    weight2: number;
    inputImage3:File | null;
    weight3: number;
    inputImage4: File | null;
    weight4: number;
    inputImage5: File | null;
    weight5: number;
    inputImage6: File | null;
    weight6: number;
}
