import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import PrimaryButton from '../PrimaryButton';
import { DownloadImageIcon } from '../../../../assets/icons/components/DownloadImageIcon';
import { ReloadIcon } from '../../../../assets/icons/components/ReloadIcon';
import { AppLabels } from '../../../../utils/constants';
import { ImageHelpers } from '../../../../utils/helpers';
import { DemoPageResultsSectionProps } from '../../../../types/propsTypes';

const DEFAULT_FILENAME = 'cadviz_render_.png';

const DemoPageResultsSection = ({
    generatedImage,
    isFullVersion,
    reset
}: DemoPageResultsSectionProps): React.JSX.Element => {

    const triggerImageLink = (url: string, action: 'download'| 'view'): void => {
        const a = document.createElement('a');
        a.href = url;
        if (action === 'download') {
            a.download = DEFAULT_FILENAME;
        } else {
            a.target = '_blank';
        }
        a.click();
    };

    const downloadImage = (url: string): void => {
        const blob = ImageHelpers.getBlobFromBase64String(url);
        const imageUrl =  URL.createObjectURL(blob);
        triggerImageLink(imageUrl, 'download');
    };

    const showImage = (url: string): void => {
        const blob = ImageHelpers.getBlobFromBase64String(url);
        const file = new File([blob], DEFAULT_FILENAME, {
            type: 'image/png',
        });
        const imageUrl =  URL.createObjectURL(file);
        triggerImageLink(imageUrl, 'view');
    };

    return (
        <Wrapper>
            {generatedImage &&
                <PreviewSection>
                    <ResultImage src={generatedImage}  onClick={()=> showImage(generatedImage)} full={isFullVersion}/>
                    <ActionPanel>
                        <PrimaryButton variant="contained"
                            onClick={()=> {downloadImage(generatedImage)}}>
                            <DownloadImageIcon/>
                        </PrimaryButton>
                    </ActionPanel>
                </PreviewSection>
            }
            <LinkButton onClick={reset}>
                <ReloadIcon/>
                <Typography>
                    {AppLabels.demoPageLabels.buttonLabels.reset}
                </Typography>
            </LinkButton>
        </Wrapper>
    );
};

export default DemoPageResultsSection;

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: '10px'
}));

const LinkButton = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    color: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',

    '& .MuiTypography-root': {
        fontFamily: '"Ubuntu Mono",  monospace',
        fontSize: '16px',
        lineHeight: '22px',
        color:theme.palette.primary.dark,
        textDecorationColor: theme.palette.primary.dark,
        textDecoration: 'underline'
    }
}));

const ResultImage = styled('img')<{full: boolean}>(({ full }) => ({
    maxHeight: full ? '1600px' : '358px',
    maxWidth: full ? '100%' : '568px',
    cursor: 'zoom-in',

    '@media (max-width:500px)': {
        maxWidth: '320px'
    },
    display: 'flex',
    position: 'relative',
    objectFit: 'contain',
    borderRadius: '14px'
}));

const PreviewSection = styled(Box)(() => ({
    display: 'flex',
    position: 'relative',
    justifyContent: 'center'
}));

const ActionPanel = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 7,
    bottom: 7,

    '& .MuiButton-root': {
        height: '24px',
        minWidth: '38px',
        padding: '0px !important'
    },

    '& svg': {
        width: '18px',
        height: '18px'
    }
}));
