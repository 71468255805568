import config from '../../../config';
import { UseRequest } from '../useRequest/UseRequest';
import { LoginDto, LoginInfo, RegistrationInfo, UserRegistrationDto } from '../../../types/dto';
import { AuthApiItems } from '../../../types/apiTypes';

export const  useAuthApi = (): AuthApiItems => {

    const { requestPostWithJson } = UseRequest();

    const register = async(data: RegistrationInfo): Promise<UserRegistrationDto> => {
        const response = await requestPostWithJson(
            `${config.apiUrl}/${config.path.users.baseUrl}`,
            config.path.users.endpoint.register, 
            { data }
        );

        return response?.data;
    };

    const signIn = async(data: LoginInfo): Promise<LoginDto | null> => {
        const response = await requestPostWithJson(
            `${config.apiUrl}/${config.path.auth.baseUrl}`,
            config.path.auth.endpoint.login, 
            { data }
        );

        if(!response?.data?.accessToken ||  !response?.data?.refreshToken) {
            return null;
        }

        return response?.data;
    };
  
    return {
        register,
        signIn
    };
};
