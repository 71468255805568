import { Theme } from '@mui/material/styles';

const Components = {
    MuiCssBaseline: {
        styleOverrides: (theme: Theme) => ({
            body: {
                backgroundColor: theme.palette.neutrals.main,
                color: theme.palette.neutrals.contrastText
            },
        }),
    },

    MuiPaper: {
        styleOverrides: {
            root: {
                background: 'transparent',
                boxShadow: 'none'
            },
        },
    },

    MuiPickersLayout: {
        styleOverrides:{
            root: {
                marginTop:'5px',
                fontSize: '14px',
                fontFamily: 'Roboto500',
                color: '#686868',
                border: '1px solid #686868',
                borderRadius: '6px',
                backgroundColor: '#2B2B2B',
            },
        },
    },

    MuiDateCalendar: {
        styleOverrides:{
            root: {
                '& svg': {
                    color: '#FFFFFF',
                    fill: '#686868',
                },
                '& .MuiTypography-root': {
                    color: '#FFFFFF',
                }
            },
        },
    },

    MuiPickersYear: {
        styleOverrides:{
            root: {
                button: {
                    color: '#FFFFFF',
                    ':disabled': {
                        color: '#686868',
                    },
                }
                
            },
        },
    },

    MuiDayCalendar: {
        styleOverrides:{
            weekContainer : {
                button: {
                    color: '#FFFFFF',
                    ':disabled': {
                        color: '#686868 !important',
                    },
                    ':not(.Mui-selected)': {
                        border: 'none'
                    }

                },
                
            },
        },
    },

    MuiList: {
        styleOverrides: {
            root: {
                backgroundColor: '#2B2B2B',
                border: '1px solid #686868',
                borderRadius: '6px',
            },
        },
    },

    MuiMenuItem: {
        styleOverrides: {
            root: {
                minHeight: '40px',
                fontSize: '14px',
                fontFamily: 'Roboto400',
                color: '#686868',
                backgroundColor: 'transparent',
                
            }
        }
    },
};

export default Components;