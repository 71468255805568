import React from 'react';
import { useMainPage } from '../../context/MainPageContext/UseMainPage';
import WebBrowserLayout from '../../layouts/WebBrowserLayout';
import MobileLayout from '../../layouts/MobileLayout';


const GeneratePage = (): JSX.Element => {
  const { matches } = useMainPage();
  return (
    <>
      { matches  ? ( <WebBrowserLayout/> ) : ( <MobileLayout/> ) }
    </>
  );
};

export default GeneratePage;