import React, {PropsWithChildren, createContext, useContext, useState} from 'react';
import { ErrorMessages } from '../../utils/constants';
import { LoaderContextItems } from '../../types/contextTypes';

const LoaderContext = createContext<LoaderContextItems>( {} as LoaderContextItems);

const LoaderContextProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
    const [loaderState, setLoaderState] = useState(false);

    const showLoader = (): void => {
        setLoaderState(true);
    };

    const hideLoader = (): void => {
        setLoaderState(false);
    };

    const value: LoaderContextItems = {
        loaderState,
        showLoader,
        hideLoader
    };

    return (
        <LoaderContext.Provider value={value}>
            {children}
        </LoaderContext.Provider>
    );
};

export const useLoader = (): LoaderContextItems  => {
    const context = useContext(LoaderContext);
    if (!context) {
        throw new Error(ErrorMessages.context.loaderContextError);
    }
    return context;
};

export default LoaderContextProvider;