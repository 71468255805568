import React from 'react'
import { Box, styled } from '@mui/material';
import {DownloadIcon, FullScreenMaxIcon, FullScreenMinIcon} from '../../assets'
import { ImageToolbarProps } from '../../types/propsTypes';


const ImageToolbar = ({ downloadImage, toggleFullScreen, isOpened }: ImageToolbarProps): JSX.Element => {
  return (
    <Toolbar isOpened={isOpened}>
      <ToolbarIcon path={DownloadIcon} onClick={downloadImage}/>
      <ToolbarIcon path={isOpened ? FullScreenMinIcon: FullScreenMaxIcon} onClick={toggleFullScreen}/>
    </Toolbar>
  );
};

const Toolbar = styled(Box)<{isOpened: boolean}>(({ isOpened }) => ({
  position: 'absolute',
  right: '0',
  top: isOpened ? '-35px': '0',
  borderRadius: '5px',
  overflow: 'hidden',
  display: 'flex',
  opacity: '1',
}));


const ToolbarIcon = styled(Box)<{ path: string }>(({ path, theme }) => ({
  backgroundColor: theme.palette.neutrals.dark,
  width: '35px',
  height: '35px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '25px',
  backgroundPosition: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.neutrals.light
  },
  backgroundImage: `url(${path})`
}));

export default ImageToolbar;