export const PASSWORD_PLACEHOLDER = '********';
export const EMPTY_VALUE = 'None';
export const INFO_NOT_AVAILABLE_LABEL =  '—';
export const DEFAULT_USER_INFO = {
  id: '-999',
  name: 'name',
  roleId: 1,
  expirationDate: '',
  password: PASSWORD_PLACEHOLDER,
  dateCreated: '',
  discordName: INFO_NOT_AVAILABLE_LABEL
};
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DEFAULT_DATE_LABEL_FORMAT = 'DD/MM/YYYY';