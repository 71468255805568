import React, {PropsWithChildren, createContext, useContext, useState} from 'react';
import { MainPageElements } from '../../types/contextTypes';
import {useMediaQuery } from '@mui/material';
import { ErrorMessages } from '../../utils/constants';
import { Sections } from '../../types/enums';

const MainPageContext =  createContext<MainPageElements>( {} as MainPageElements);

export const MainPageProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {

    const [showGallery, setShowGallery] = useState(false);
    const [activeSection, setActiveSection] = useState(Sections.GenerationInput);
    const matches = useMediaQuery('(min-width:1000px)');

    const value: MainPageElements = {
        matches,
        showGallery,
        activeSection,
        setShowGallery,
        setActiveSection
    };

    return (
        <MainPageContext.Provider value={value}>
            {children}
        </MainPageContext.Provider>
    );
};

export const useMainPage = (): MainPageElements  => {
    const context = useContext(MainPageContext);
    if (!context) {
        throw new Error(ErrorMessages.context.mainPageContextError);
    }
    return context;
};