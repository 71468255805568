//images
export { default as UploadFileBackground } from './images/addImage.png';
export { default as AppLogoImage } from './images/logoImage.svg';

// icons
export { default as ChevronBackIcon } from './icons/chevronBack.png';
export { default as FullScreenMaxIcon } from './icons/fullScreenMax.png';
export { default as FullScreenMinIcon } from './icons/fullScreenMin.png';
export { default as GalleryToggleIcon } from './icons/gallery.png';
export { default as DownloadIcon } from './icons/download.png';
export { default as AddFileIcon } from './icons/add.png';
export { default as CloseIcon } from './icons/close.png';

const ReferenceImages = [
    require('./images/samples/1.png'),
    require('./images/samples/2.png'),
    require('./images/samples/3.png'),
    require('./images/samples/4.png'),
    require('./images/samples/5.png'),
    require('./images/samples/6.png'),
    require('./images/samples/7.png'),
    require('./images/samples/8.png'),
    require('./images/samples/8.png'),
    require('./images/samples/10.png'),
    require('./images/samples/11.png'),
    require('./images/samples/12.png'),
    require('./images/samples/13.png'),
    require('./images/samples/14.png'),
    require('./images/samples/15.png'),
    require('./images/samples/16.png'),
    require('./images/samples/17.png'),
    require('./images/samples/18.png'),
    require('./images/samples/19.png'),
    require('./images/samples/20.png'),
    require('./images/samples/21.png'),
  ];
  
export default ReferenceImages;

export const BaseImagesReferences = [
  require('./images/samples/baseImages/base1.jpg'),
  require('./images/samples/baseImages/base2.jpeg'),
  require('./images/samples/baseImages/base3.jpg'),
  require('./images/samples/baseImages/base4.jpg'),
];

export const StyleImagesReferences = [
  require('./images/samples/styleImages/style1.jpeg'),
  require('./images/samples/styleImages/style2.png'),
  require('./images/samples/styleImages/style3.jpg'),
  require('./images/samples/styleImages/style4.jpg'),
];
