import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LogOutIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24" 
            {...props}>
           <path fillRule="evenodd" clipRule="evenodd" d="M3.82383 1.93478C3.46475 1.93478 3.10673 2.09455 2.8327 2.40255C2.55681 2.71264 2.39257 3.14558 2.39257 3.6087V20.3913C2.39257 20.8544 2.55681 21.2874 2.8327 21.5975C3.10673 21.9055 3.46475 22.0652 3.82383 22.0652H12.334C12.6931 22.0652 13.0511 21.9055 13.3251 21.5975C13.601 21.2874 13.7653 20.8544 13.7653 20.3913V18C13.7653 17.6038 14.077 17.2826 14.4615 17.2826C14.8461 17.2826 15.1578 17.6038 15.1578 18V20.3913C15.1578 21.1966 14.8738 21.9801 14.3517 22.567C13.8277 23.1559 13.1034 23.5 12.334 23.5H3.82383C3.05439 23.5 2.33012 23.1559 1.80616 22.567C1.28406 21.9801 1 21.1966 1 20.3913V3.6087C1 2.80339 1.28406 2.01985 1.80616 1.43303C2.33012 0.844116 3.05439 0.5 3.82383 0.5H11.9085C12.6866 0.5 13.4807 0.846492 14.078 1.37249C14.6775 1.90039 15.1578 2.68263 15.1578 3.6087V6C15.1578 6.3962 14.8461 6.71739 14.4615 6.71739C14.077 6.71739 13.7653 6.3962 13.7653 6V3.6087C13.7653 3.21416 13.5566 2.80075 13.1732 2.46315C12.7877 2.12364 12.3053 1.93478 11.9085 1.93478H3.82383ZM17.5781 5.89886C17.8616 5.63114 18.302 5.65087 18.5619 5.94293L22.817 10.7255C23.061 10.9998 23.061 11.4208 22.817 11.6951L18.5619 16.4777C18.302 16.7697 17.8616 16.7895 17.5781 16.5217C17.2947 16.254 17.2755 15.8002 17.5354 15.5082L20.7209 11.9277H7.83642C7.45187 11.9277 7.14014 11.6065 7.14014 11.2103C7.14014 10.8141 7.45187 10.4929 7.83642 10.4929H20.7209L17.5354 6.91245C17.2755 6.62039 17.2947 6.16659 17.5781 5.89886Z" fill="white"/>
        </SvgIcon>
    );
};