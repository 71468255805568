
import React, { ReactNode } from 'react';
import { Button, styled } from '@mui/material';

export interface PrimaryButtonProps {
    onClick?: () => void;
    variant: 'outlined' | 'contained';
    children: ReactNode;
    className?: 'link-button' | 'large'| '';
}

const PrimaryButton = ({ onClick, variant, className = '', children }: PrimaryButtonProps): React.JSX.Element => {

  return (
        <StyledButton onClick={onClick} variant={variant} className={className}>
            { children }
        </StyledButton>
    );
};

export default PrimaryButton;

const StyledButton = styled(Button)(({ theme }) => ({
    height: '30px',
    width: 'fit-content',
    boxShadow: 'none',
    textTransform: 'none',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.neutrals.contrastText,
    fontFamily: '"Ubuntu Mono",  monospace',

    '&:hover': {
        backgroundColor: theme.palette.primary.light
    },

    '&.MuiButton-outlined': {
        backgroundColor: theme.palette.neutrals.contrastText,
        borderColor: theme.palette.neutrals.grey,
        color: theme.palette.primary.dark,
        '&:hover': {
            backgroundColor: theme.palette.neutrals.inactive
        }
    },

    '&.link-button': {
        borderRadius: '15px'
    },

    '&.large': {
        fontSize: '20px',
        borderBottomLeftRadius: '20% 90%',
        borderBottomRightRadius: '20% 90%',
        borderTopLeftRadius: '20% 90%',
        borderTopRightRadius: '20% 90%',
        height: '35px',
        padding: '5px 30px'
    },

    'a': {
        fontFamily: '"Ubuntu Mono",  monospace',
        fontSize: '16px',
        color: theme.palette.neutrals.contrastText,
        textDecoration: 'none'
    }
}));
