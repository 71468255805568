import React, { useEffect, useState } from 'react'
import ImageDropBox from './components/ImageDropBox/ImageDropBox';
import { Box, Button, Divider, Typography, styled } from '@mui/material';
import { AppLabels } from '../../utils/constants';
import { UseMainPageForm } from '../../context/MainPageFormContext/UseMainPageForm';
import ToggleField from '../../components/ToggleField';
import { SupportedModels } from '../../types/enums';
import ReferenceLibrary from './components/ReferenceLibrary';
import { MainPageFormProps } from '../../types/propsTypes';
import { ExpandMenuIcon } from '../../assets/icons/components/ExpandMenuIcon';
import ReferenceImages, { BaseImagesReferences, StyleImagesReferences } from '../../assets';

const DEFAULT_REFERENCE_LIBRARY_LENGTH = 4;

const MainPageForm = ({ isWebLayout = false }: MainPageFormProps): React.JSX.Element => {

    const {
        mainImage,
        readyToGenerate,
        submitGeneration,
        generationType,
        setGenerationType,
        setMainImage,
        setStyleImage
    } = UseMainPageForm();

    const [referenceImages, setReferenceImages] = useState<[string[], string[]]>([[], []]);
    const [isFirstSectionActive, setIsFirstSectionActive] = useState<boolean>(true);
    const [isSecondSectionActive, setIsSecondSectionActive] = useState<boolean>(true);

    const onSubmit = (): void => {
        submitGeneration();
    };

    // Temporary unused
    const getReferences = (numberOfElements: number): [string[], string[]] => {
        const firstReferences = ReferenceImages.sort(() => Math.random() - 0.5).slice(0, numberOfElements);
        const secondReferences = ReferenceImages.filter((imagePath) => !firstReferences.includes(imagePath))
        .sort(() => Math.random() - 0.5)
        .slice(0, numberOfElements);
    
        return [firstReferences, secondReferences];
    };

    // Temporary unused
    // useEffect(() => {
    //     if (isWebLayout) {
    //         const imagesPath = getReferences(DEFAULT_REFERENCE_LIBRARY_LENGTH);
    //         setReferenceImages(imagesPath);
    //     }
    // }, []);

    return (
    <>
        <FormContainer small={isWebLayout}>
            <Wrapper>
                <ImageDropBox
                    isFirst = {true}
                    label={isWebLayout ? AppLabels.mainPage.loadLabelWeb : AppLabels.mainPage.loadLabelMobile}/>
                    <LibrarySection>
                        <ReferenceLibrary
                            isHidden={!isFirstSectionActive}
                            label={AppLabels.mainPage.referenceLabel}
                            setImage={setMainImage}
                            // imagesPath={referenceImages[0]}/>    // Temporary unused
                            imagesPath={BaseImagesReferences}/>
                    </LibrarySection>
                <ExpandIconWrapper>
                    <ContentDivider/>
                        <ExpandIcon active={+isFirstSectionActive} onClick={() => setIsFirstSectionActive(!isFirstSectionActive)}/>
                    <ContentDivider/>
                </ExpandIconWrapper>
                { mainImage && ( 
                    <>
                        <ModelSection>
                            <SectionLabel>{AppLabels.mainPage.modelLabel}</SectionLabel>
                            <ToggleField
                                selectedOption={generationType}
                                optionsList={Object.values(SupportedModels)}
                                setSelectedOption={setGenerationType}/>
                        </ModelSection>
                        <InfluenceLabel variant="subtitle2">
                            {AppLabels.mainPage.subTitle}
                        </InfluenceLabel>
                        <ImageDropBox
                            isFirst = {false}
                            label={isWebLayout ? AppLabels.mainPage.loadLabelWeb : AppLabels.mainPage.loadLabelMobile}/>
                            <LibrarySection>
                                <ReferenceLibrary
                                    isHidden={!isSecondSectionActive}
                                    label={AppLabels.mainPage.referenceLabel}
                                    setImage={setStyleImage}
                                    // imagesPath={referenceImages[1]}/>    // Temporary unused
                                    imagesPath={StyleImagesReferences}/>
                            </LibrarySection>
                        <ExpandIconWrapper>
                            <ContentDivider/>
                            <ExpandIcon active={+isSecondSectionActive} onClick={() => setIsSecondSectionActive(!isSecondSectionActive)}/>
                            <ContentDivider/>
                        </ExpandIconWrapper>
                    </>)
                }
            </Wrapper>
        </FormContainer>
        <Footer small={isWebLayout}>
            <GenerateButton
                variant="contained"
                onClick={onSubmit}
                disabled={!readyToGenerate}>
                {AppLabels.mainPage.generateLabel}
            </GenerateButton>
        </Footer>
    </>
  );
};

export default MainPageForm;


const FormContainer = styled(Box)<{small: boolean;}>(({ small }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  height: small ? 'calc(100% - 83px)' :  'calc(100dvh - 150px)',

}));

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '20px',
}));

const LibrarySection = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'start',
    width: '100%',
    margin: '14px 0px 0px 0px'
}));

const ModelSection = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'start',
    margin: '20px 0',
}));

const InfluenceLabel = styled(Typography)(() => ({
    margin: '20px 0',
    alignSelf: 'flex-start'
}));

const SectionLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto400',
    fontSize: '16px',
    color: theme.palette.neutrals.grey1,
    width: '100%',
    marginBottom: '20px'
}));

const Footer = styled(Box)<{small: boolean;}>(({ small }) => ({
    display: 'flex',
    height: '83px',
    padding: '25px 0px',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px -12px 20px 0px rgba(30, 30, 30, 0.6)',

    ...(small && { minWidth: '300px', }),
    ...(small && {  maxWidth: '500px', }),

    width: small ? '20%' : '100%',
    position: 'fixed',
    bottom: '0'
}));

const GenerateButton = styled(Button)<{ disabled: boolean }>(({ theme, disabled }) => ({
    width: '260px',
    height: '43px',
    transition: 'all 0.5s',
    fontFamily: 'Roboto500',
    fontSize: '18px',

    color: disabled ?
        `${theme.palette.primary.inactive1} !important` :
        `${theme.palette.neutrals.contrastText} !important`,
    backgroundColor:  disabled ?
        `${theme.palette.primary.light} !important` :
        `${theme.palette.primary.main} !important`,

    '&:hover': {
        backgroundColor:  disabled ?
            theme.palette.primary.light :
            theme.palette.primary.main
    },
}));

const ExpandIconWrapper = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
}));
  
const ContentDivider = styled(Divider)(({ theme }) => ({
    background: theme.palette.primary.light1,
    width: 'calc(50% - 12px)',
    height: '1px',
    margin: 'auto'
}));
  
const ExpandIcon = styled(ExpandMenuIcon)<{active: number}>(({ active }) => ({
    transform: active ? 'rotate(180deg)' : 'unset',
    transition: 'all 0.5s',
    cursor: 'pointer'
}));