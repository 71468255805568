import React, { useState } from 'react';
import { Box, IconButton, styled } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { Link } from 'react-router-dom';
import { AddIcon } from '../../../../assets/icons/components/AddIcon';
import { DemoPageStates } from '../../../../types/enums';
import { AppLabels } from '../../../../utils/constants';
import { useDemoPage } from '../../../../context/DemoPageContext/useDemoPage';
import { PanelMainSection } from '../PanelMainSection';
import PrimaryButton from '../PrimaryButton';
import { PanelLabel } from '../PanelLabel';

const UPLOAD_WAIT_TIME = 2000;
const ALLOWED_TYPES = ['JPEG', 'PNG', 'JPG', 'WEBP'];
const MAX_SIZE_MB = 8;
const PRO_LINK = 'https://www.cadviz.engineer/cadviz-subscribe';

const DemoPageUploadPanel = (): React.JSX.Element => {

    const [errorMessage, setErrorMessage] = useState<string>('');
    const {
        setMainImage,
        setCurrentStep,
        setImageUrl,
        setTimeoutId,
        numberOfRemainingGeneration
    } = useDemoPage();

    const handleUpload = (file: File): void => {
        setCurrentStep(DemoPageStates.UPLOADING);
        setMainImage(file);  
        setImageUrl(URL.createObjectURL(file));

        const id = setTimeout(() => {
            setCurrentStep(DemoPageStates.STYLING);
        }, UPLOAD_WAIT_TIME);

        setTimeoutId(id);
    };


    return (
        <SectionWrapper>
            {   numberOfRemainingGeneration
                ?
                    <FileUploader
                        dropMessageStyle={{ backgroundColor: 'transparent' }}
                        hoverTitle={' '}
                        onSizeError={() => setErrorMessage(AppLabels.demoPageLabels.uploadSectionLabels.sizeError)}
                        onTypeError={() => setErrorMessage(AppLabels.demoPageLabels.uploadSectionLabels.typeError)}
                        maxSize={MAX_SIZE_MB}
                        handleChange={handleUpload}
                        types={ALLOWED_TYPES}>
                        <PanelMainSection className={'action'}>
                            <StyledButton><AddIcon/></StyledButton>
                            {errorMessage &&
                                <PanelLabel className={'error'}>
                                    {errorMessage}
                                </PanelLabel>
                            }
                            <PanelLabel className={'main'}>
                                {AppLabels.demoPageLabels.uploadSectionLabels.placeholderMessage}
                            </PanelLabel>
                            <PanelLabel variant="caption">
                                {AppLabels.demoPageLabels.uploadSectionLabels.allowedTypes}
                            </PanelLabel>
                            <PrimaryButton variant="contained" className={'large'}>
                                {AppLabels.demoPageLabels.buttonLabels.upload}
                            </PrimaryButton>
                        </PanelMainSection>
                    </FileUploader>
                :
                    <PanelMainSection className={'info'}>
                        <PrimaryButton variant="contained" className={'link-button'}>
                            <InfoLink to={PRO_LINK} target="_blank">
                                <strong> {AppLabels.demoPageLabels.buttonLabels.moreInfo}</strong>
                            </InfoLink>
                        </PrimaryButton>
                    </PanelMainSection>
            }
        </SectionWrapper>
    );
};

export default DemoPageUploadPanel;

const SectionWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: '20px',

    '& label': {
        borderRadius: '14px'
    }
}));

const StyledButton = styled(IconButton)(({ theme }) => ({
    width: '32px',
    height: '32px',
    color: theme.palette.primary.contrastText,
    padding: '8px 0px 8px 0px',
    marginBottom: '26px',

    '& svg': {
        width: '30px',
        height: '30px',

        'path': {
            strokeWidth: '3px'
        },

        'circle': {
            strokeWidth: '3px'
        }
    },

    '&:hover': {
        background: 'transparent'
    }
}));

const InfoLink = styled(Link)(({ theme }) => ({
    cursor: 'pointer',
    color: theme.palette.neutrals.main,
    textDecorationColor: theme.palette.neutrals.main,
    fontSize: '12px'
}));
