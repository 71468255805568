import React, { DragEvent, useEffect, useState } from 'react'
import { useDrag } from '../../../../context/DragContext/useDrag';
import { Box, Typography, styled } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { UploadFileBackground, AddFileIcon } from '../../../../assets'
import { UseMainPageForm } from '../../../../context/MainPageFormContext/UseMainPageForm';
import { ImageDropBoxProps } from '../../../../types/propsTypes';

const ImageDropBox = ({ isFirst, label }: ImageDropBoxProps): JSX.Element => {
  const {
    mainImage,
    styleImage,
    setMainImage,
    setStyleImage,
  } = UseMainPageForm();

    const { draggedImage } = useDrag();
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    useEffect(() => {
        if (isFirst) {
            setImageUrl(mainImage ? URL.createObjectURL(mainImage) : '');
        } else {
            setImageUrl(styleImage ? URL.createObjectURL(styleImage) : '');
        }
    }, [isFirst, mainImage, styleImage]);

    const handleDragOver = (event: DragEvent<HTMLDivElement>): void => {
        event.stopPropagation();
        event.preventDefault();
    };

    const handleDrop = async (): Promise<void> => {
        if(draggedImage) {
            handleUpload(draggedImage);
        }
    };

    const handleUpload = (file: File):void => {
        if (isFirst) {
            setMainImage(file);
        } else {
            setStyleImage(file);
        }
    };

    const ImagePlaceholder = (): JSX.Element => {
        return (
            <ImagePlaceholderWrapper>
                <AddFileImage src={AddFileIcon} />
                <Typography variant="subtitle1" alignItems={'center'} paddingBottom={'10px'}>
                   {label}
                </Typography>
            </ImagePlaceholderWrapper>
        );
    };

  return (
    <Wrapper withImage={!!imageUrl}>
        <Dropzone
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            enabled={!!draggedImage}
        />
         <FileUploader
            required={!imageUrl}
            handleChange={handleUpload}
            types={['JPEG', 'PNG', 'JPG', 'WEBP', 'AVIF', 'HEIC']}>
            {imageUrl ? <Image src={imageUrl} /> : <ImagePlaceholder />}
         </FileUploader>
    </Wrapper>
  );
};

export default ImageDropBox;

const Wrapper = styled(Box)<{ withImage: boolean }>(({ theme, withImage }) => ({
    minHeight: '50px',
    minWidth: 'calc(100% - 42px)',
    margin: 'auto',
    overflow: 'hidden',

    transition: 'all 0.5s',
    position: 'relative',
    border: withImage ? 'none' : `2px dashed ${theme.palette.primary.light1}`,

    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.neutrals.grey,
    borderRadius: '10px'
}));

const Dropzone = styled(Box)<{enabled: boolean}>(({ enabled }) => ({
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex : enabled ? 1 : 0,
}));

const Image = styled('img')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100%',
    height: '100%',
    borderRadius: '10px',
}));

const AddFileImage = styled('img')(() => ({
    width: '55px',
    height: '55px',

    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    marginBottom: '20px',
    margin: 'auto'
}));

const ImagePlaceholderWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',

    width: '100%',
    height: '100%',
    backgroundImage: `url(${UploadFileBackground})`,
    backgroundSize: 'contain',

    minHeight:  '150px',
    minWidth: 'calc(100% - 42px)',
    objectFit: 'cover',
    aspectRatio: '3/2'
}));
