import React, {PropsWithChildren, createContext, useContext, useState} from 'react';
import { LocalStorageItems } from '../../types/contextTypes';
import { ErrorMessages, LocalStorageKeys } from '../../utils/constants';

export const SAVED_HISTORY_LIMIT = 4;

const LocalStorageContext = createContext<LocalStorageItems>( {} as LocalStorageItems);

export const LocalStorageProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {

    const [generatedImagesHistoryKey, setGeneratedImagesHistoryKey] = useState('');
    const [storageUsername, setStorageUsername] = useState(
        localStorage.getItem(LocalStorageKeys.USERNAME_KEY),
    );
    const [storageUserRole, setStorageUserRole] = useState(
        localStorage.getItem(LocalStorageKeys.USER_ROLE_KEY),
    );
    const [storageAccessToken, setStorageAccessToken] = useState(
        localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN_KEY),
    );
    const [storageRefreshToken, setStorageRefreshToken] = useState(
        localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN_KEY),
    );

    const getGeneratedImages = (): string[] => {
        if(!generatedImagesHistoryKey) {
            setGeneratedImagesHistoryKey(LocalStorageKeys.GENERATED_IMAGES_HISTORY_KEY);
        }
        const generatedImageString = localStorage.getItem(LocalStorageKeys.GENERATED_IMAGES_HISTORY_KEY);
        if (generatedImageString) {
            const imagesHistory = JSON.parse(generatedImageString);
            return imagesHistory;
        }
        return [];
    };

    const updateGeneratedImages = (generatedImages : string[]): void => {
        try {
            let generatedImageString = '';
            if (generatedImages.length > SAVED_HISTORY_LIMIT) {
                generatedImageString = JSON.stringify(generatedImages.slice(0, SAVED_HISTORY_LIMIT));
            } else {
                generatedImageString = JSON.stringify(generatedImages);
            }
            localStorage.setItem(LocalStorageKeys.GENERATED_IMAGES_HISTORY_KEY, generatedImageString);
        } catch (error) {
            console.error(`${ErrorMessages.localStorage.failedToSetItem} Error: ${error}`);
        }
    };

    const handleSetStorageUsername = (value: string): void => {
        localStorage.setItem(LocalStorageKeys.USERNAME_KEY, value);
        setStorageUsername(value);
    };

    const handleSetStorageAccessToken = (value: string): void=> {
        localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN_KEY, value);
        setStorageAccessToken(value);
    };
    const handleSetStorageRefreshToke = (value: string): void=> {
        localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN_KEY, value);
        setStorageRefreshToken(value);
    };

    const handleSetStorageUserRole = (value: string): void => {
        localStorage.setItem(LocalStorageKeys.USER_ROLE_KEY, value);
        setStorageUserRole(value);
    };

    const value: LocalStorageItems = {
        getImagesHistory: getGeneratedImages,
        updateImagesHistory: updateGeneratedImages,
        storageUsername,
        setStorageUsername: handleSetStorageUsername,
        storageUserRole,
        setStorageUserRole: handleSetStorageUserRole,
        storageAccessToken,
        setStorageAccessToken: handleSetStorageAccessToken,
        storageRefreshToken,
        setStorageRefreshToken: handleSetStorageRefreshToke,
    };

    return (
        <LocalStorageContext.Provider value={value}>
            {children}
        </LocalStorageContext.Provider>
    );
};

export const useLocalStorage = (): LocalStorageItems  => {
    const context = useContext(LocalStorageContext);
    if (!context) {
        throw new Error(ErrorMessages.context.localStorageContextError);
    }
    return context;
};