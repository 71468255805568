import React from 'react';
import { Box, GlobalStyles, styled } from '@mui/material';
import DemoPageFooter from './components/DemoPageFooter';
import DemoPageUploadPanel from './components/DemoPageUploadPanel';
import DemoPageStatusPanel from './components/DemoPageStatusPanel';
import DemoPageResultsSection from './components/DemoPageResultsSection';
import { useDemoPage } from '../../context/DemoPageContext/useDemoPage';
import { DemoPageStates } from '../../types/enums';

const DemoPage = (): React.JSX.Element => {

    const {
        currentStep,
        cancelHandler,
        generatedImage,
        resetPageInfo,
        isFullVersion
    } = useDemoPage();

    return (
        <>
            <GlobalStyles
                styles={{
                    body: {
                        backgroundColor: 'transparent'
                    }
                }}
            />
            <Wrapper>
                { currentStep === DemoPageStates.NONE && <DemoPageUploadPanel/> }
                { (currentStep === DemoPageStates.UPLOADING || currentStep === DemoPageStates.STYLING) && 
                    <DemoPageStatusPanel onCancel={cancelHandler}/>
                }
                { currentStep === DemoPageStates.DONE &&
                    <DemoPageResultsSection
                        isFullVersion={isFullVersion}
                        generatedImage={generatedImage}
                        reset={resetPageInfo}/>
                }
                <DemoPageFooter
                    currentStep={currentStep}/>
            </Wrapper>
        </>
    );
};

export default DemoPage;

const Wrapper = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundSize:'50px 50px',
    gap: '20px'
}));
