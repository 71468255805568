import React, { useState } from 'react'
import { Box, styled } from '@mui/material';
import { UseMainPageForm } from '../../context/MainPageFormContext/UseMainPageForm';
import { useMainPage } from '../../context/MainPageContext/UseMainPage';
import GenerationResultWeb from './layouts/GenerationResultWeb';
import GenerationResultMobile from './layouts/GenerationResultMobile';
import { ErrorMessages } from '../../utils/constants';

const GenerationResult = (): JSX.Element => {
  const { activeImageUrl } = UseMainPageForm();
  const { matches } = useMainPage();

  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const downloadImage = (): void => {
    // // Check if the input is a URL
    if (activeImageUrl.startsWith('http') || activeImageUrl.startsWith('https')) {
        // If it's a URL, fetch the image data
      fetch(activeImageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a blob URL for the fetched image data
          const blobUrl = URL.createObjectURL(blob);

          // Calculate the image extension from the URL
          const extension = activeImageUrl.split('.').pop();
          const defaultFilename = `image.${extension || 'jpg'}`;

          initiateDownload(blobUrl, defaultFilename);
          // Revoke the blob URL to free up resources
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error(`${ErrorMessages.imagesError.fetchImageError}:`, error);
      });
    } else if (activeImageUrl.startsWith('data:image')) {
        // If it's a Base64 string, convert it to a blob
        const byteCharacters = atob(activeImageUrl.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]);

        // Calculate the image extension from the Base64 string
        const mimeType = activeImageUrl.split(';')[0];
        const extension = mimeType.split('/')[1];
        const defaultFilename = `downloaded_image.${extension || 'jpg'}`;

        const blobUrl = URL.createObjectURL(blob);
        initiateDownload(blobUrl, defaultFilename);
        // Revoke the blob URL to free up resources
        URL.revokeObjectURL(blobUrl);
    } else if (activeImageUrl.startsWith('blob:http')) {
        const defaultFilename = `image.${'jpg'}`;
        initiateDownload(activeImageUrl, defaultFilename);
    } else {
        // Handle unsupported input
        console.error(ErrorMessages.imagesError.unsupportedInputError);
    }
};

  const initiateDownload = (blobUrl: string, filename: string): void =>  {
    // Create a temporary anchor element for downloading
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = blobUrl;
    a.download = filename;

    // Trigger a click event to initiate the download
    a.click();

    // Remove the temporary anchor element
    document.body.removeChild(a);
  }

  const toggleFullScreen = (): void => {
    if (!isFullScreenEnabled) {
      handleOpenFullScreen();
    } else {
      handleCloseFullScreen();
    }
  }

  const handleOpenFullScreen = (): void => {
    setIsFullScreenEnabled(true);
    setOpen(true);
  };

  const handleCloseFullScreen = (): void => {
    setIsFullScreenEnabled(false);
    setOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLImageElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    if (event.detail === 2) {
      handleOpenFullScreen();
    }
  };

  return (
    <PageContainer>
      { matches ? 
        <GenerationResultWeb 
          activeImageUrl={activeImageUrl}
          open={open}
          downloadImage={downloadImage}
          toggleFullScreen={toggleFullScreen}
          handleClick={handleClick}
          handleCloseFullScreen={handleCloseFullScreen}/> : 
        <GenerationResultMobile
          activeImageUrl={activeImageUrl}
          open={open}
          downloadImage={downloadImage}
          toggleFullScreen={toggleFullScreen}
          handleClick={handleClick}
          handleCloseFullScreen={handleCloseFullScreen}/>
      }
  </PageContainer>
  );
};


export default GenerationResult;

const PageContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  minHeight: '100%'
}));
