import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '../src/hooks/api/useRequest/axios';
import reportWebVitals from './reportWebVitals';
import { LocalStorageProvider } from './context/LocalStorageContext/UseLocalStorage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <LocalStorageProvider>
    <App />
  </LocalStorageProvider>
);

reportWebVitals();
