import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import { useMainPage } from '../../context/MainPageContext/UseMainPage';
import Header from './components/Header/Header';

const MainPage = (): JSX.Element => {
  const { matches } = useMainPage();

  return (
    <Wrapper>
      <HeaderWrapper matches={+matches}>
        <Header/>
      </HeaderWrapper>
      <Outlet />
    </Wrapper>
  );
};


const Wrapper = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  }));

  const HeaderWrapper = styled(Box)<{ matches: number }>(({ matches, theme }) => ({
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    borderBottom: 1,
    borderColor: 'transparent',
    height: '67px',
    fontWeight: 'bold',
    background: matches ? theme.palette.neutrals.grey: 'none',
    justifyContent: matches ? 'start': 'center'
  }));

export default MainPage;