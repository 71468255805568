import React from 'react';
import { Box, ToggleButton, ToggleButtonGroup, Typography, styled } from '@mui/material';
import { ToggleFieldProps } from './ToggleField.types';

const ToggleField = ({
    selectedOption,
    setSelectedOption,
    optionsList,
    disabled = false
}: ToggleFieldProps): React.JSX.Element => {

    const handleChange = (_: React.MouseEvent<HTMLElement>, option: string): void => {
        if (option) {
            setSelectedOption(option);
        }
    };

  return (
    <Container>
        <ToggleGroup
            fullWidth
            exclusive
            disabled={disabled}
            value={selectedOption}
            onChange={handleChange}>
            {
                optionsList.map((item: string, index: number) => (
                    <ToggleOption disableRipple value={item} key={index}>
                        <ToggleLabel>{item}</ToggleLabel>
                    </ToggleOption>
                ))
            }
        </ToggleGroup>
    </Container>
  );
}

export default ToggleField;

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    height: '40px',
    width: '100%',

    backgroundColor: theme.palette.neutrals.grey,
    borderRadius: '6px'
}));

const ToggleGroup = styled(ToggleButtonGroup)(() => ({
    width: '100%',
    height: '40px',
    padding: '2px 1px'
}));

const ToggleOption = styled(ToggleButton)(({ theme }) => ({
    padding: '10px',
    textTransform: 'none',

    borderRadius: '6px',
    border: 'none',

    '&:hover': {
        backgroundColor: 'transparent',
    },

    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor:  theme.palette.primary.main
        }
    }
}));

const ToggleLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto400',
    fontSize: '14px',
    color: theme.palette.neutrals.contrastText,
}));
  