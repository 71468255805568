import React from 'react';
import { InfluenceImageBlockProps } from './InfluenceImageBlock.types';
import { Box, Button, Typography, styled } from '@mui/material';
import { AppLabels } from '../../../../utils/constants';

const InfluenceImageBlock = ({
  id,
  label,
  currentImage,
  setCurrentImage,
   weight,
  setWeight
}: InfluenceImageBlockProps): JSX.Element => {

  const onInfluenceImageInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.files?.length ? event.target.files[0]: null;
    if(value) {
      setCurrentImage(value);
    }
  };

  const onInfluenceInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = +event.target.value;
    if(value) {
      setWeight(value);
    }
  };

  return (
    <ImageBlockWrapper>
      <UploadButton component="label" variant="contained" >
        {label}
        <input type="file"
        onChange={onInfluenceImageInputChange}
        hidden />
      </UploadButton>
      <InputLabel>{AppLabels.extendedFormLabels.weight}</InputLabel>
      <Input type="number" id={id} name={id} min="0" max="200" step="1" value={weight} onChange={onInfluenceInputChange} />
      { currentImage && <Image src={URL.createObjectURL(currentImage)} />}
  </ImageBlockWrapper>
  );
};

export default InfluenceImageBlock;

const UploadButton = styled(Button)<{component: string}>(({ theme }) => ({
  width: '40%',
  height: '50px',
  margin: '10px 0px',
  
  transition: 'all 0.5s',
  fontFamily: 'Roboto400',
  color: theme.palette.neutrals.contrastText,
}));
  
const Image = styled('img')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  maxWidth: '325px',
  height: '100%',
  borderRadius: '10px',
}));

const InputLabel = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto500',
  paddingRight: '5px',
  fontSize: '14px',

  color: theme.palette.primary.light1,
  textAlign: 'start'
}));

const Input = styled('input')(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.inactive}`,
  color: theme.palette.neutrals.contrastText,
  borderRadius: '4px',

  fontSize: '14px',
  fontFamily: 'Roboto400',

  padding: '10px 14px 10px 13px',
  minHeight: '40px',
  width: '100%',
  background: 'transparent',

  ':focus' :{
    outline: 'none',
    borderColor: theme.palette.neutrals.contrastText,
  }
}));

const ImageBlockWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',

  height: '100%',
  width: '100%',
  gap: '5px'
}));