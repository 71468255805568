import axios from 'axios';
import config from '../../../config';
import { LocalStorageKeys } from '../../../utils/constants';
import { updateAccessToken } from '../../../context/AuthContext/UpdateAccessToken';

if (localStorage[LocalStorageKeys.ACCESS_TOKEN_KEY]) {
    updateAccessToken();
}

axios.interceptors.response.use(
    (resp) => resp,
    async (error) => {
        if (error.response && error.response.status === 401 && !error.config.refreshed) {
            error.config.refreshed = true;
            const token = localStorage[LocalStorageKeys.REFRESH_TOKEN_KEY];

            const response = await axios.post(`${config.apiUrl}/${config.path.auth.baseUrl}${config.path.auth.endpoint.refresh}`,
                {},
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN_KEY, response.data.accessToken);
                updateAccessToken();

                error.config.headers[
                    'Authorization'
                ] = `Bearer ${response.data.accessToken}`;

                return axios(error.config);
            } else if (error.config.url !== `${config.apiUrl}/${config.path.auth.baseUrl}${config.path.auth.endpoint.login}`) {
                localStorage.removeItem(LocalStorageKeys.USERNAME_KEY);
                localStorage.removeItem(LocalStorageKeys.USER_ROLE_KEY);
                localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN_KEY);
                localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN_KEY);
                window.location.href = '/';
            }
        }
        return error.response;
    },
);
