import React from 'react';
import { Box, styled } from '@mui/material';
import FullSizeImageDialogWeb from '../components/FullSizeImageDialogWeb';
import { GenerationResultProps } from '../../../types/propsTypes';
import ImageToolbar from '../../../components/ImageToolbar';


const GenerationResultWeb = ({ 
    activeImageUrl,
    open,
    downloadImage,
    toggleFullScreen,
    handleClick,
    handleCloseFullScreen
}: GenerationResultProps): JSX.Element => {
  return (
        <>
        { activeImageUrl && 
            <>
                <ContentWrapper>
                <ImageWrapper>
                    { !open && <ImageToolbar
                        isOpened={false} 
                        downloadImage={downloadImage} 
                        toggleFullScreen={toggleFullScreen}/> 
                    }
                    <Image src={activeImageUrl} onClick={handleClick}/>
                </ImageWrapper>
                </ContentWrapper>
                <FullSizeImageDialogWeb
                open={open}
                handleClose={handleCloseFullScreen}
                imageUrl={activeImageUrl}>
                <ImageToolbar
                    isOpened={true} 
                    downloadImage={downloadImage} 
                    toggleFullScreen={toggleFullScreen}/>
                </FullSizeImageDialogWeb>
            </>
        }
        </>
    );
};

const ContentWrapper = styled(Box)(() => ({
    padding: '0 5px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  }));

const ImageWrapper = styled(Box)(() => ({
    position: 'relative',
    margin:'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    height: '100%',
    '&:not(:hover) div': {
     display: 'none'
    }
}));

const Image = styled('img')(() => ({
    borderRadius: '10px',
    width: 'auto',
    height: 'min-content',
    maxHeight: '100%',
    maxWidth: '100%'
  }));
  
export default GenerationResultWeb;