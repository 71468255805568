import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { ErrorMessages } from '../../utils/constants';
import { useStyleDriveApi } from '../../hooks/api/useStyleDriveApi/useStyleDriveApi';
import { DEFAULT_SWITCH1, DEFAULT_SWITCH2, DEFAULT_WEIGHT2, GenerateImageConfig } from '../../types/styleDriveApi/styleDriveApiTypes';
import { useLoader } from '../LoaderContext/UseLoader';
import { useMainPage } from '../MainPageContext/UseMainPage';
import { generateSeed } from '../../utils/helpers';
import { Sections, SupportedModels } from '../../types/enums';
import { MainPageFormSettings } from '../../types/contextTypes';
import { useLocalStorage } from '../LocalStorageContext/UseLocalStorage';
import config from '../../config';

const MainPageFormContext = createContext<MainPageFormSettings>({} as MainPageFormSettings);

export const MainPageFormProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
    const [mainImage, setMainImage] = useState<File | null>(null);
    const [styleImage, setStyleImage] = useState<File | null>(null);
    const [readyToGenerate, setReadyToGenerate] = useState(false);
    const [activeImageUrl, setActiveImageUrl] = useState('');
    const [generationsHistory, setGenerationsHistory] = useState<string[]>([]);
    const [generationType, setGenerationType] = useState<string>(SupportedModels.SD);

    const { generateImage } = useStyleDriveApi();
    const { showLoader, hideLoader} = useLoader();
    const { setActiveSection } = useMainPage();
    const { getImagesHistory, updateImagesHistory } = useLocalStorage();

    useEffect(() => {
        const currentHistory = getImagesHistory()
        setGenerationsHistory(currentHistory);
    }, []);
    
    useEffect(() => {
        setReadyToGenerate(!!mainImage && !!styleImage);
    }, [mainImage, styleImage]);

    useEffect(() => {
        if(activeImageUrl?.length && !generationsHistory.includes(activeImageUrl)) {
            updateGenerationsHistory([activeImageUrl, ...generationsHistory]);
        }
    }, [activeImageUrl]);
    
    const updateGenerationsHistory = (historyItems: string[]): void => {
        updateImagesHistory(historyItems);
        setGenerationsHistory(historyItems);
    };

    const submitGeneration = async (): Promise<void> => {
        showLoader();
        const generationConfig: GenerateImageConfig = {
            text: '',
            seed: generateSeed(),
            inputImage1: mainImage,
            switch1: DEFAULT_SWITCH1, 
            switch2: DEFAULT_SWITCH2, 
            inputImage2: styleImage,
            weight2: DEFAULT_WEIGHT2,
            inputImage3: null,
            weight3: DEFAULT_WEIGHT2,
            inputImage4: null,
            weight4: DEFAULT_WEIGHT2,
            inputImage5: null,
            weight5: DEFAULT_WEIGHT2,
            inputImage6: null,
            weight6: DEFAULT_WEIGHT2,

        }

        const result = await generateImage(generationConfig, generationType);

        hideLoader();
        const imageUrl = result ? `${config.baseUrl}${result.resultImage}` : '';
        setActiveImageUrl(imageUrl);
        setActiveSection(Sections.GenerationResult);
    };

    const clearForm = (): void => {
        setMainImage(null);
        setStyleImage(null);
        setActiveImageUrl('');
    };

    const value: MainPageFormSettings = {
        mainImage,
        styleImage,
        setMainImage,
        setStyleImage,
        activeImageUrl,
        setActiveImageUrl,
        clearForm,
        generationsHistory,
        readyToGenerate,
        submitGeneration,
        generationType,
        setGenerationType
    };

    return <MainPageFormContext.Provider value={value}>{children}</MainPageFormContext.Provider>;
};

export const UseMainPageForm = (): MainPageFormSettings => {
    const context = useContext(MainPageFormContext);
    if (!context) {
        throw new Error(ErrorMessages.context.mainPageInputError);
    }
    return context;
}