import React from 'react';
import { Box, styled } from '@mui/material';
import FullSizeImageDialogMobile from '../components/FullSizeImageDialogMobile'
import { CloseIcon } from '../../../assets';
import { useMainPage } from '../../../context/MainPageContext/UseMainPage';
import { Sections } from '../../../types/enums';
import { GenerationResultProps } from '../../../types/propsTypes';
import ImageToolbar from '../../../components/ImageToolbar';

const GenerationResultMobile = ({
    activeImageUrl,
    open,
    downloadImage,
    toggleFullScreen,
    handleClick,
    handleCloseFullScreen
}: GenerationResultProps): JSX.Element => {
    const { setActiveSection} = useMainPage();

    const backToMain = (): void => {
        setActiveSection(Sections.GenerationInput);
    }

    return (
        <>
        { activeImageUrl &&
            <>
                <ContentWrapper>
                    <CloseButton path={CloseIcon} onClick={backToMain}/>
                    <ImageWrapper>
                        <Image src={activeImageUrl} onClick={handleClick}/>
                        { !open && <ImageToolbar
                            isOpened={false}
                            downloadImage={downloadImage} 
                            toggleFullScreen={toggleFullScreen}/>
                        }
                    </ImageWrapper>
                </ContentWrapper>
                <FullSizeImageDialogMobile
                    open={open}
                    handleClose={handleCloseFullScreen}
                    imageUrl={activeImageUrl}>
                    <ImageToolbar
                        isOpened={true}
                        downloadImage={downloadImage} 
                        toggleFullScreen={toggleFullScreen}/>
                </FullSizeImageDialogMobile>
            </>
        }
      </>
    );
};

export default GenerationResultMobile;
const ContentWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    position:'relative',
    height: 'inherit'
}));

const CloseButton = styled(Box)<{ path: string }>(({ path }) => ({
    marginTop: '10px',
    marginRight: '10px',
    position: 'absolute',
    top: '0',
    right: '0',
    width: '35px',
    height: '35px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '25px',
    backgroundPosition: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    display: 'flex',
    backgroundImage: `url(${path})`
}));

const ImageWrapper = styled(Box)(() => ({
    height: 'inherit',
    position: 'relative',
    margin:'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& div': {
        top: 'auto',
        bottom: '-45px',
        backgroundColor: 'transparent'
    }
}));

const Image = styled('img')(() => ({
    borderRadius: '10px',
    width: 'auto',
    maxHeight: '100%',
    maxWidth: '100%'
}));