import React, {PropsWithChildren, createContext, useContext, useEffect, useState} from 'react';
import { ErrorMessages } from '../../utils/constants';
import { DemoPageStates } from '../../types/enums';
import { DemoPageContextItems } from '../../types/contextTypes';
import { getAbortController } from '../../utils/helpers';
import { useDemoPageApi } from '../../hooks/useDemoPageApi/useDemoPageApi';
import { GenerateCadvizConfig } from '../../types/apiTypes';

const GENERATION_LIMIT_PER_DAY = 10;
const FULL_VERSION_QUERY_PARAM = 'pro-mode';
const PUBLIC_PRO_PATH = 'home-3';
const PROTECTED_PRO_PATH = 'cadviz-pro';
const DemoPageContext = createContext<DemoPageContextItems | null>(null);

export const DemoPageProvider = ({ children }: PropsWithChildren<unknown>): React.JSX.Element => {

    const { controller, signal } = getAbortController();
    const { generateCadviz } = useDemoPageApi();

    const [mainImage, setMainImage] = useState<File | null>(null);
    const [generatedImage, setGeneratedImage] = useState<string | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [currentStep, setCurrentStep] = useState<DemoPageStates>(DemoPageStates.NONE);
    const [timeoutId , setTimeoutId ] = useState<number| string | NodeJS.Timeout | undefined>(undefined);
    const [numberOfRemainingGeneration, setNumberOfRemainingGeneration] = useState<number| null>(GENERATION_LIMIT_PER_DAY);
    const [isFullVersion, setIsFullVersion] = useState<boolean>(false);

    useEffect(() => {
        if (!mainImage) {
            return;
        }

        window.parent.postMessage('ready', '*');
        window.addEventListener('message', pathValidation);

        return () => {
            window.removeEventListener('message', pathValidation);
        };

    }, [mainImage]);
    

    const pathValidation = (event: MessageEvent<any>): void => {
        const info = event.data as { pathname?: string; search?: string };

        if (!info.pathname) {
            return;
        }

        let allowFullVersion = true;
        const parentPath = info.pathname?.replaceAll('/', '');
    
        if (parentPath === PROTECTED_PRO_PATH) {
            setIsFullVersion(allowFullVersion);
            return;
        }
    
        if (parentPath !== PUBLIC_PRO_PATH) {
            allowFullVersion = false;
        }
    
        const queryParams = info.search?.replace('?', '').split('&');

        if (queryParams) {
            const [paramKey, paramValue] = queryParams[0]?.split('=')?.map(element => element?.trim());
        
            if (paramKey !== FULL_VERSION_QUERY_PARAM || paramValue !== 'true') {
                allowFullVersion = false;
            }
        } else {
            allowFullVersion = false;
        }

        setIsFullVersion(allowFullVersion);
    };
    
    const cancelUpload = (): void => {
        resetPageInfo();
        clearTimeout(timeoutId);
    };

    const cancelGeneration = (): void => {
        controller.abort();
        resetPageInfo();
    };

    const cancelHandler = (): void => {
        if (currentStep === DemoPageStates.UPLOADING) {
            cancelUpload();
        } else if (currentStep === DemoPageStates.STYLING) {
            cancelGeneration();
        }
    };

    const generateImages = async (): Promise<void> => {
        if (!mainImage) {
            resetPageInfo();
            return;
        }

        const generationConfig: GenerateCadvizConfig = {
            image: mainImage
        }

        const result = await generateCadviz(generationConfig, isFullVersion, signal);

        if (!result) {
            resetPageInfo();
            return;
        }

        if (!isFullVersion) {
            setNumberOfRemainingGeneration(result?.remainingGenerations || null);
        }

        if (result?.image) {
            const imgSrc = 'data:image/png;base64,' + result.image;
            setGeneratedImage(imgSrc);
            setCurrentStep(DemoPageStates.DONE);
        } else {
            resetPageInfo();
        }
    };

    const resetPageInfo = (): void => {
        setMainImage(null);
        setImageUrl(null);
        setCurrentStep(DemoPageStates.NONE);
    };

    useEffect(() => {
        if (currentStep === DemoPageStates.STYLING) {
            generateImages();
        }
    }, [currentStep]);

    const value: DemoPageContextItems = {
        currentStep,
        numberOfRemainingGeneration,
        setMainImage,
        generatedImage,
        setCurrentStep,
        cancelHandler,
        setImageUrl,
        imageUrl,
        setTimeoutId,
        resetPageInfo,
        isFullVersion
    };
    return <DemoPageContext.Provider value={value}>{children}</DemoPageContext.Provider>;
};

export const useDemoPage = (): DemoPageContextItems => {
    const context = useContext(DemoPageContext);
    if (!context) {
        throw new Error(ErrorMessages.context.demoPageContextError);
    }
    return context;
}
