import React from 'react';
import { Box, styled } from '@mui/material';
import ExtendedGenerateForm from './components/ExtendedGenerateForm';
import ExtendedGenerationResult from './components/ExtendedGenerationResult';


const ExtendedGeneratePage = (): JSX.Element => {

    return (
        <ContentWrapper>
        <SideBarWrapper>
            <ExtendedGenerateForm></ExtendedGenerateForm>
        </SideBarWrapper>
        <GenerationResultWrapper>
            <ExtendedGenerationResult></ExtendedGenerationResult>
        </GenerationResultWrapper>
    </ContentWrapper>
    );
};

export default ExtendedGeneratePage;

const ContentWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',

    width: '100%',
    height: 'calc(100vh - 67px)',

    backgroundColor: theme.palette.neutrals.light1,
}));

const SideBarWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.neutrals.main,
    minWidth: '300px',
    width: '380px',
    padding: '15px',

    overflow: 'auto',
    flexDirection: 'column',
}));

const GenerationResultWrapper = styled(Box)(() => ({
    flex: '1',
    flexDirection: 'column',
    display: 'flex',
    overflow: 'auto',

    padding: '20px',
    width: 'calc(100% - 380px)',
}));
