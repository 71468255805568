import { Box, Typography, styled } from '@mui/material';
import React from 'react';
import { AppLabels } from '../../../../utils/constants';
import { Link } from 'react-router-dom';
import { DemoPageStates } from '../../../../types/enums';
import { DemoPageFooterProps } from '../../../../types/propsTypes';

const TERMS_OF_SERVICE_LINK = 'https://www.depix.ai/terms-of-service';
const PRIVACY_POLICY_LINK = 'https://www.depix.ai/privacy-policy';
const ABOUT_LINK = 'https://www.depix.ai/';

const DemoPageFooter = ({ currentStep }: DemoPageFooterProps): React.JSX.Element => {
  return (
        <Wrapper>
            { currentStep === DemoPageStates.NONE &&
                <InfoFooterContainer>
                    <Typography>
                        {AppLabels.demoPageLabels.footerLabels.fistMessage}
                        <InfoLink to={TERMS_OF_SERVICE_LINK} target="_blank">
                            {AppLabels.demoPageLabels.footerLabels.termsOfServiceLabel}
                        </InfoLink>{'.'}
                    </Typography>

                    <Typography>
                        {AppLabels.demoPageLabels.footerLabels.secondMessagePartOne}
                        <InfoLink to={ABOUT_LINK} target="_blank">
                            {AppLabels.demoPageLabels.footerLabels.infoLabel}
                        </InfoLink>
                        {AppLabels.demoPageLabels.footerLabels.secondMessagePartTwo}
                        <InfoLink to={PRIVACY_POLICY_LINK} target="_blank">
                            {AppLabels.demoPageLabels.footerLabels.policyLabel}
                        </InfoLink>{'.'}
                    </Typography>
                </InfoFooterContainer>
            }
        </Wrapper>
    );
};

export default DemoPageFooter;

const Wrapper = styled(Box)(() => ({
    width: '100%',
    height: '55px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '@media (max-width:500px)': {
        width: '90%',
        height: 'fit-content',

        '& .MuiTypography-root': {
            textAlign: 'center'
        }
    },
}));

const InfoFooterContainer = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.light1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& .MuiTypography-root': {
        fontFamily: 'Poppins',
        fontSize: '12px',
        lineHeight: '14px'
    }
}));

const InfoLink = styled(Link)(({ theme }) => ({
    cursor: 'pointer',
    color: theme.palette.neutrals.main,
    textDecorationColor: theme.palette.neutrals.main,
    fontSize: '12px'
}));
