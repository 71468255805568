import React from 'react';
import { FormControlLabel, styled, Checkbox, Typography } from '@mui/material';
import { CheckboxFieldProps } from './CheckboxField.types';
import { CheckboxCheckedIcon } from '../../assets/icons/components/CheckboxCheckedIcon';
import { CheckboxUncheckedIcon } from '../../assets/icons/components/CheckboxUncheckedIcon';

const CheckboxField = ({ id, label, checked, onChange}: CheckboxFieldProps): JSX.Element => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(event.target.checked);
    };
    return (
        <StyledFormControlLabel
            id={id}
            name={id}
            control={
                <Checkbox
                    disableRipple
                    checked={checked}
                    onChange={handleChange}
                    checkedIcon={<CheckboxCheckedIcon/>}
                    icon={<CheckboxUncheckedIcon/>}
                    
                />
            }
            labelPlacement="start"
            label={<InputLabel>{label}</InputLabel>}
        />
    );
};

export default CheckboxField;

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    height: '24px',
    margin: 0,

    '&:hover': {
        '.MuiCheckbox-root': {
            backgroundColor: 'transparent',
            color: theme.palette.neutrals.contrastText,
        }
    },

    '& .MuiCheckbox-root': {
        color: theme.palette.primary.light1,
        backgroundColor: 'transparent',
        padding: '0px',
        margin: '9px',
        height: '24px',
        width: '24px',
    },

    '&.MuiCheckbox-root, .Mui-checked': {
        color: `${theme.palette.neutrals.contrastText} !important`,
    },

    '& svg': {
        height: '18px',
        width: '18px'
    }
}));

const InputLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    fontSize: '14px',
    color: theme.palette.primary.light1,
}));
