import * as React from 'react';
import { Box, styled, Modal } from '@mui/material';
import { FullSizeImageDialogProps } from '../../../types/propsTypes';

const FullSizeImageDialogWeb = ({ open, handleClose, imageUrl, children }: FullSizeImageDialogProps): JSX.Element => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}>
          <Overlay>
            <OverlayImageContainer>
              {children}
              <OverlayImage src={imageUrl}></OverlayImage>
            </OverlayImageContainer>
          </Overlay>
        </Modal>
    </div>
  );
};

export default FullSizeImageDialogWeb;

const Overlay = styled(Box)(() => ({
    display: 'flex',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: '999',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
}));

const OverlayImageContainer = styled(Box)(() => ({
    maxWidth: '80%',
    maxHeight: '80%',
    position: 'relative',
}));

const OverlayImage = styled('img')(() => ({
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '5px'
}));
