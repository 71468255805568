export const ErrorMessages = {
    context: {
        localStorageContextError: 'Can not use useLocalStorage out of LocalStorageContext.',
        dragContextError: 'Can not use useDrag out of DragContext.',
        authContextError: 'Can not use useAuth out of AuthContext.',
        mainPageContextError: 'Can not use useMainPage out of MainPageContext.',
        loaderContextError: 'Can not use useLoader out of LoaderContext.',
        mainPageInputError: 'Can not use UseMainPageInput out of MainPageInputContext.',
        requestContext: 'Can not use UseRequest out of RequestContext.',
        extendedGenerationContextError: 'Can not use useExtendedGeneration out of ExtendedGenerationContext.',
        demoPageContextError: 'Can not use useDemoPage out of DemoPageContext.',
    },
    imagesError: {
        fetchImageError: 'Error fetching the image',
        unsupportedInputError: 'Unsupported input format. Please provide a valid URL or Base64 image data.',
    },
    localStorage: {
        failedToSetItem: 'Failed to set item on storage.'
    }
};