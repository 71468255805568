import React from 'react';
import { Box, styled } from '@mui/material';
import MainPageForm from '../pages/MainPageForm';
import GenerationResult from '../pages/GenerationResult';
import { useMainPage } from '../context/MainPageContext/UseMainPage';
import { Sections } from '../types/enums';
import Gallery from '../pages/Gallery';


const MobileLayout = (): JSX.Element => {
    const { activeSection } = useMainPage();
  return (
    <ContentWrapper>
        { activeSection === Sections.GenerationInput  &&  (
            <SideBarWrapper>
                <MainPageForm></MainPageForm>
            </SideBarWrapper>
        )}
        { activeSection === Sections.GenerationResult  &&  (
            <GenerationResultWrapper>
                <GenerationResult/>
            </GenerationResultWrapper>
        )}
        { activeSection === Sections.Gallery  &&  (
            <GalleryWrapper>
                <Gallery/>
            </GalleryWrapper> 
        )}
    </ContentWrapper> 
  );
};

const ContentWrapper = styled(Box)(() => ({
    overflow: 'auto',
    height: 'calc(100dvh - 67px)'
  }));

const SideBarWrapper = styled(Box)(() => ({
    flex: '1',
    flexDirection: 'column',
}));

const GenerationResultWrapper = styled(Box)(() => ({
    flex: '1',
    flexDirection: 'column',
    display: 'flex',
    overflow: 'auto',
    height: '100%',
}));

const GalleryWrapper = styled(Box)(() => ({
    flex: '1',
    flexDirection: 'column',
    display: 'flex',
    overflow: 'auto',
    alignContent: 'flex-end'
    
}));

export default MobileLayout;