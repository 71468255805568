import React, {PropsWithChildren, createContext, useContext, useState} from 'react';
import { AuthContextItems } from '../../types/contextTypes';
import { ErrorMessages, LocalStorageKeys } from '../../utils/constants';
import { useAuthApi } from '../../hooks/api/useAuthApi/useAuthApi';
import { useLoader } from '../LoaderContext/UseLoader';
import { useLocalStorage } from '../LocalStorageContext/UseLocalStorage';
import axios from 'axios';
import { UserRoles } from '../../types/enums';
import { LoginInfo } from '../../types/dto';
import { updateAccessToken } from './UpdateAccessToken';

const AuthContext = createContext<AuthContextItems>({} as AuthContextItems);

export const AuthProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
    const { signIn } = useAuthApi();
    const { showLoader, hideLoader} = useLoader();
    const {
        storageUserRole,
        setStorageUsername,
        storageAccessToken,
        setStorageUserRole,
        setStorageAccessToken,
        setStorageRefreshToken
    } = useLocalStorage();

    const [isSignedIn, setSignedIn] = useState<boolean>(!!storageAccessToken);
    const [isRequestError, setRequestError] = useState<boolean>(false);
    const [username, setUsername] = useState<string>('');
    const [userRole, setUserRole] = useState<UserRoles| null>(storageUserRole ? +storageUserRole : null);

    const handleLogin = (username: string, password: string): void => {
        const data: LoginInfo = {
            name: username,
            password,
        };

        setRequestError(false);
        showLoader();

        signIn(data).then((user) => {            
            setSignedIn(!!user);
            setRequestError(!user);
            hideLoader();

            setUsername(username);
            setStorageUsername(username);

            setStorageUserRole(user ? user.roleId.toString() : '');

            setStorageAccessToken(user ? user.accessToken : '');
            setStorageRefreshToken(user ? user.refreshToken : '');
            setUserRole(user ? user.roleId : null);

            updateAccessToken();
        });
    };

    const signOut = (): void => {
        setSignedIn(false);
        localStorage.removeItem(LocalStorageKeys.USERNAME_KEY);
        localStorage.removeItem(LocalStorageKeys.USER_ROLE_KEY);
        localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN_KEY);
        localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN_KEY);
        delete axios.defaults.headers.common['Authorization'];
    };

    const value: AuthContextItems = {
        isSignedIn,
        isRequestError,
        username,
        userRole,
        handleLogin,
        signOut,
        setRequestError
    };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextItems => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error(ErrorMessages.context.authContextError);
    }
    return context;
}