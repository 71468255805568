const ThemeTypography = {
    body14: {
        fontFamily: 'Roboto400',
        fontSize: '14px',
        lineHeight: '20px'
    },
    body16: {
        fontFamily: 'Roboto400',
        fontSize: '16px',
        lineHeight: '22px'
    },
    subtitle1: {
        fontFamily: 'Roboto400',
        fontSize: '14px',
        lineHeight: '16px',
        '@media (max-width:1500px)': {
            fontSize: '12px',
            lineHeight: '14px',
        },
    },
    subtitle2: {
        fontFamily: 'Roboto400',
        fontSize: '16px',
        lineHeight: '22px',
    }
};

export default ThemeTypography;