import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ApplyStyleIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 56 69" 
            {...props}>
            <path d="M8.45318 34.9339L8.63232 34.5756C9.37874 33.1276 10.5581 31.9483 12.0061 31.2018L12.3644 31.0227C13.7826 30.3061 13.7826 28.2759 12.3644 27.5444L12.0061 27.3652C10.5581 26.6188 9.37874 25.4395 8.63232 23.9914L8.45318 23.6332C7.73662 22.215 5.70636 22.215 4.97488 23.6332L4.79574 23.9914C4.04932 25.4395 2.86998 26.6188 1.42193 27.3652L1.06365 27.5444C-0.354548 28.2609 -0.354548 30.2912 1.06365 31.0227L1.42193 31.2018C2.86998 31.9483 4.04932 33.1276 4.79574 34.5756L4.97488 34.9339C5.69144 36.3521 7.72169 36.3521 8.45318 34.9339Z" fill="currentColor"/>
            <path d="M51.1931 46.9512L50.8348 46.7721C49.3867 46.0257 48.2074 44.8464 47.461 43.3983L47.2818 43.04C46.5653 41.6218 44.535 41.6218 43.8035 43.04L43.6244 43.3983C42.878 44.8464 41.6986 46.0257 40.2506 46.7721L39.8923 46.9512C38.4741 47.6678 38.4741 49.6981 39.8923 50.4296L40.2506 50.6087C41.6986 51.3551 42.878 52.5345 43.6244 53.9825L43.8035 54.3408C44.5201 55.759 46.5503 55.759 47.2818 54.3408L47.461 53.9825C48.2074 52.5345 49.3867 51.3551 50.8348 50.6087L51.1931 50.4296C52.6113 49.713 52.6113 47.6827 51.1931 46.9512Z" fill="currentColor"/>
            <path d="M9.58774 16.2585L10.0654 16.4974C11.5583 17.2587 12.7526 18.453 13.5139 19.9459L13.7528 20.4236C14.5738 22.0358 16.2159 23.036 18.0223 23.036C19.8286 23.036 21.4707 22.0358 22.3067 20.4236L22.5456 19.9459C23.3069 18.453 24.5012 17.2587 25.994 16.4974L26.4717 16.2585C28.084 15.4375 29.0842 13.7954 29.0842 11.989C29.0842 10.1827 28.084 8.54059 26.4717 7.7046L25.994 7.46574C24.5012 6.7044 23.3069 5.51013 22.5456 4.01729L22.3067 3.53959C21.4856 1.92732 19.8435 0.927124 18.0223 0.927124C16.201 0.927124 14.5738 1.92732 13.7378 3.53959L13.499 4.01729C12.7376 5.51013 11.5434 6.7044 10.0505 7.46574L9.57281 7.7046C7.96055 8.52566 6.96035 10.1678 6.96035 11.989C6.96035 13.8103 7.96055 15.4375 9.57281 16.2735L9.58774 16.2585ZM11.618 11.6905L12.0957 11.4516C14.4395 10.2573 16.3055 8.3913 17.4998 6.04755L17.7386 5.56984C17.7386 5.56984 17.8282 5.3907 18.0223 5.3907C18.2163 5.3907 18.291 5.51013 18.3059 5.56984L18.5448 6.04755C19.739 8.3913 21.6051 10.2573 23.9488 11.4516L24.4265 11.6905C24.4265 11.6905 24.6057 11.78 24.6057 11.9741C24.6057 12.1682 24.4713 12.2428 24.4265 12.2577L23.9488 12.4966C21.6051 13.6909 19.739 15.5569 18.5448 17.9007L18.3059 18.3784C18.3059 18.3784 18.2163 18.5575 18.0223 18.5575C17.8282 18.5575 17.7535 18.4232 17.7386 18.3784L17.4998 17.9007C16.3055 15.5569 14.4395 13.6909 12.0957 12.4966L11.618 12.2577C11.618 12.2577 11.4389 12.1682 11.4389 11.9741C11.4389 11.78 11.5732 11.7054 11.618 11.6905Z" fill="currentColor"/>
            <path d="M54.1787 13.0788L51.2826 10.1827C50.1182 9.01829 48.5657 8.37637 46.9236 8.37637C45.2814 8.37637 43.7289 9.01829 42.5645 10.1827L11.5583 41.1889C11.5583 41.1889 11.5284 41.2337 11.5135 41.2635C11.3344 41.4576 11.1851 41.6666 11.0806 41.9055V41.9353L6.76628 52.221C6.0945 53.8183 6.46771 55.6396 7.70677 56.8637C8.5129 57.6549 9.57281 58.0729 10.6626 58.0729C11.2299 58.0729 11.8121 57.9535 12.3644 57.7146L22.4411 53.2958L22.5008 53.2659C22.7396 53.1614 22.9337 53.0122 23.1128 52.848C23.1278 52.833 23.1576 52.8181 23.1875 52.8032L54.1937 21.797C55.3581 20.6326 56 19.08 56 17.4379C56 15.7958 55.3581 14.2432 54.1937 13.0788H54.1787ZM21.5901 48.056L16.3055 42.7713L39.534 19.5428L44.8187 24.8274L21.5901 48.056ZM51.0139 18.6322L47.9835 21.6626L42.6988 16.378L45.7293 13.3475C46.3563 12.7056 47.4759 12.7056 48.1178 13.3475L51.0139 16.2436C51.3274 16.5571 51.5066 16.99 51.5066 17.4379C51.5066 17.8857 51.3274 18.3037 51.0139 18.6322Z" fill="currentColor"/>
            </SvgIcon>
    );
};
