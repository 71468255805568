import { Box, Typography, styled } from '@mui/material';
import React from 'react'
import { ReferenceLibraryProps } from '../../../../types/propsTypes';

const DEFAULT_FILENAME = 'image.png';

const ReferenceLibrary = ({
    imagesPath,
    setImage,
    label,
    isHidden = false
}: ReferenceLibraryProps): React.JSX.Element => {
    
    const onClick = async (event: any): Promise<void> => {
        if (!event?.target?.src) {
            return;
        }

        const result = await fetch(event.target.src);
        const blob = await result.blob();
        const file = new File([blob], DEFAULT_FILENAME, {
            type: 'image/png',
        });
        setImage(file);
    };

    return (
        <LibrarySection isHidden={isHidden}>
            <SectionLabel isHidden={isHidden}>{label}</SectionLabel>
            <SectionContent>
            {
                imagesPath?.length && imagesPath.map((item: string, index: number) => (
                    <ReferenceImage
                        isHidden={isHidden}
                        draggable="false"
                        length={imagesPath?.length}
                        src={item}
                        key={index}
                        onClick={onClick}>
                    </ReferenceImage>
                ))
            }
            </SectionContent>
        </LibrarySection>
    );
};

export default ReferenceLibrary;

const LibrarySection = styled(Box)<{isHidden: boolean}>(({ isHidden }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    
    textAlign: 'start',
    maxHeight: isHidden ? '0': '200px',
    minWidth: '100%',
    opacity: isHidden ? '0' : '1',
    transition: 'all 0.5s ease-out',
}));

const SectionLabel = styled(Typography)<{ isHidden: boolean }>(({ theme, isHidden }) => ({
    fontFamily: 'Roboto400',
    width: '100%',
    color: theme.palette.neutrals.grey1,

    fontSize:  isHidden ? '0px' : '12px',
    marginBottom: isHidden ? '0px' : '12px',
    maxHeight: isHidden ? '0px': '20px',
    opacity: isHidden ? '0' : '1',

    transition: 'all 0.5s ease-out',
}));

const SectionContent = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    width: '100%'
}));

const ReferenceImage = styled('img')<{length: number, isHidden: boolean}>(({length, isHidden}) => ({
    // 5 in accordance with gap: '5px' in the SectionContent
    width: `calc((100% - ${5 * (length - 1)}px) / ${length})`,
    objectFit: 'cover',
    borderRadius: '6px',
    cursor: 'pointer',
    aspectRatio: '1/1',

    maxHeight: isHidden ? '0px': '100px',
    opacity: isHidden ? '0' : '1',
    transition: 'all 0.5s ease-out',
}));
