import React from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import { Box, styled, IconButton } from '@mui/material';
import { NavigationPaths, Sections, UserRoles } from '../../../../types/enums';
import { useMainPage } from '../../../../context/MainPageContext/UseMainPage';
import { AppLogoImage, ChevronBackIcon } from '../../../../assets';
import { UseMainPageForm } from '../../../../context/MainPageFormContext/UseMainPageForm';
import { useAuth } from '../../../../context/AuthContext/UseAuth';
import { GalleryIcon } from '../../../../assets/icons/components/GalleryIcon';
import { UserSettingsIcon } from '../../../../assets/icons/components/UserSettingsIcon';
import { LogOutIcon } from '../../../../assets/icons/components/LogOutIcon';

const Header = (): React.JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { signOut, userRole } = useAuth();
  const { clearForm } = UseMainPageForm();

  const { 
    activeSection,
    setActiveSection,
    matches,
    showGallery,
    setShowGallery
  } = useMainPage();

  const toggleGallery = (): void => {
    navigateToGeneratePage();

    setShowGallery(!showGallery);
    setActiveSection(activeSection !== Sections.Gallery ? Sections.Gallery: Sections.GenerationInput);
  };

  const backToMain = (): void => {
    navigateToGeneratePage();

    setActiveSection(Sections.GenerationInput);
  };

  const toUsersList = (): void => {
    if(location?.pathname?.toLowerCase()?.includes(NavigationPaths.Generate) ||
      location?.pathname?.toLowerCase()?.includes(NavigationPaths.OldStyleDrive)){
      navigate('/users');
    }
  };

  const clearMainPageForm = (): void => {
    if(!matches) {
      navigateToGeneratePage();

      setActiveSection(Sections.GenerationInput);
      clearForm();
    }
  };

  const navigateToGeneratePage = (): void  => {
    if(!location?.pathname?.toLowerCase()?.includes(NavigationPaths.Generate)){
      navigate('/generate');
    }
  };

  return (
    <HeaderWrapper>
      {(!matches && activeSection === Sections.Gallery) && (<StyledIcon src={ChevronBackIcon} onClick={backToMain}/> )}
      <LogoWrapper>
        <Logo src={AppLogoImage} onClick={clearMainPageForm}/>
      </LogoWrapper>
      <HeaderIcons matches={+matches}>

        { userRole === UserRoles.Admin && ( 
          <HeaderButton>
            <HeaderIcon
              matches={+matches}
              disableRipple
              onClick={toUsersList}>
              <UserSettingsIcon />
            </HeaderIcon>
          </HeaderButton> )
        }

        <HeaderButton shown={(!matches && activeSection === Sections.Gallery)}>
          <HeaderIcon
            matches={+matches}
            disableRipple
            onClick={toggleGallery}>
            <GalleryIcon />
          </HeaderIcon>
        </HeaderButton>

        <HeaderButton>
          <HeaderIcon
            matches={+matches}
            disableRipple
            onClick={signOut}>
            <LogOutIcon />
          </HeaderIcon>
        </HeaderButton>

      </HeaderIcons>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',

  height: '100%',
  width: '100%',

  padding: '12px',
}));

const HeaderIcons = styled(Box)<{ matches: number}>(({ matches }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  alignItems: 'center',

  height: '100%',
  width: '100%',

  gap: matches ? '31px': '15px',
  paddingRight: matches ? '28px' : '11px',
}));


const HeaderButton = styled(Box)<{ shown?:boolean }>(({ shown = false }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',

  width: '24px',
  height: '24px',

  opacity: shown ? 0 : 1
}));

const HeaderIcon = styled(IconButton)<{ matches: number }>(({ matches, theme}) => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },

  '& svg': {
    color: matches ? theme.palette.primary.light : theme.palette.neutrals.main,
  }
}));

const LogoWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'end',

  height: '67px',
  padding: '7px 0px',
  gap: 0,

  position: 'absolute',
  left: 'calc(50% - 50.5px)',
}));

const Logo = styled('img')(() => ({
  height: '53px',
  width: '101px',
  objectFit: 'contain',
}));

const StyledIcon = styled('img')(() => ({
  width: '40px',
  height: '40px',

  padding: '8px',
  cursor: 'pointer',
  alignSelf: 'center'
}));

