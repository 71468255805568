import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, styled } from '@mui/material';
import { AppLogoImage } from '../../assets';
import { useAuth } from '../../context/AuthContext/UseAuth';
import InputField from '../../components/InputField';
import { AppLabels } from '../../utils/constants';


const AuthPage = (): JSX.Element => {
    const navigate = useNavigate();
    const { handleLogin, isSignedIn, isRequestError, setRequestError } = useAuth();

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [usernameError, setUsernameError] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<boolean>(false);

    const handleSubmit = (event: React.FormEvent): void => {
        event.preventDefault();
        setUsernameError(false);
        setPasswordError(false);

        setUsernameError(!username);
        setPasswordError(!password);

        if (username && password) {
            handleLogin(username, password);
        }
    };

    const onUsernameInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setUsername(event.target.value);
        setUsernameError(false);
    };

    const onPasswordInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(event.target.value);
        setPasswordError(false);
    };

    useEffect(() => {
        setRequestError(false);
    }, []);

    useEffect(() => {
        isSignedIn && navigate('/generate');
    }, [isSignedIn]);


    useEffect(() => {
        setUsernameError(isRequestError);
        setPasswordError(isRequestError);
    }, [isRequestError]);


    return (
        <AuthPageContainer>
            <LogoWrapper>
                <Logo src={AppLogoImage}/>
            </LogoWrapper>
            <AuthLabel>
                {AppLabels.authPage.title}
            </AuthLabel>
            <form id="signInForm" onSubmit={handleSubmit}>
                <SignInFormContainer>
                    <AuthInput
                        id="username"
                        name="username"
                        isRequired={true}
                        isMultiline={false}
                        isDisabled={false}
                        label='User Name'
                        value={username}
                        onChange={onUsernameInputChange}
                        error={usernameError}
                    />

                    <AuthInput
                        id="password"
                        name="password"
                        type="password"
                        isRequired={true}
                        isMultiline={false}
                        isDisabled={false}
                        label='Password'
                        value={password}
                        onChange={onPasswordInputChange}
                        error={passwordError}
                        helperText={(usernameError || passwordError) ? AppLabels.authPage.errorLabel : ''}
                    />

                    <SubmitButton
                        type="submit"
                        variant="contained">
                        {AppLabels.authPage.submitLabel}
                    </SubmitButton>
                </SignInFormContainer>
            </form>
        </AuthPageContainer>
    )
};

export default AuthPage;

const AuthPageContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    height: '100%',
    width: '100%',
    gap: '25px',
}));

const AuthLabel = styled(Typography)(({ theme }) => ({
    fontSize: '24px',
    lineHeight: '28px',
    fontFamily: 'Roboto400',

    color: theme.palette.neutrals.contrastText,
}));

const AuthInput = styled(InputField)(() => ({
    minHeight: '63px',
}));

const SignInFormContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    width: '400px',
    gap: '25px',

    '@media (max-width:800px)': {
        width: '300px',
    },

    '@media (max-width:400px)': {
        width: 'calc(100vw - 80px)',
    },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    width: '100%',
    marginTop: '40px',

    transition: 'all 0.5s',
    fontFamily: 'Roboto400',
    color: theme.palette.neutrals.contrastText,
}));

const Logo = styled('img')(() => ({
    height: '53px',
    width: '101px',
    objectFit: 'contain',
}));

export const LogoWrapper = styled(Box)(() => ({
    position: 'absolute',
    top: '10%',
    marginBottom: '50px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',

    gap: 0,

    '@media (max-height:1000px)': {
        top: '5%',
    },

    '@media (max-height:600px)': {
        top: '3%',
        left: '10%',
    },
}));