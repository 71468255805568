import { UILabels } from '../../types/UILabels';

export const AppLabels: UILabels = {
    authPage: {
        title: 'Sign in',
        submitLabel: 'sign in',
        errorLabel: 'Incorrect user name or password',
    },
    mainPage: {
        subTitle: 'Drive your style',
        loadLabelWeb: 'Click to add image',
        loadLabelMobile: 'Tap to add image',
        referenceLabel: 'No image? Try one of these',
        modelLabel: 'model for generation',
        generateLabel: 'GENERATE',

    },
    gallery: {
        emptyGalleryMessage: 'Here you will see your generated images',
        selectLabel: 'Select',
        loadImage: {
            takePhoto: 'Take Photo',
            photoLibrary: 'Photo Library',
        }
    },
    generationResult: {
        regenerate: 'Regenerate'
    },
    usersListPage: {
        headerLabels: {
            usernameLabel: 'User name',
            passwordLabel: 'Password',
            discordNameLabel: 'Discord name',
            expirationDateLabel: 'Expiration date',
            roleLabel: 'Role'
        }
    },
    extendedFormLabels: {
        text: 'Text',
        submit: 'Generate images',
        influence1: 'Influence 1',
        influence2: 'Influence 2',
        influence3: 'Influence 3',
        influence4: 'Influence 4',
        influence5: 'Influence 5',
        weight: 'Weight (0% - 200%):',
        controlImage: 'Control Image',
        preserveCarShape: 'Preserve car shape (change style only)',
        setCameraView: 'Set camera view from control image'
    },
    demoPageLabels: {
        footerLabels: {
            termsOfServiceLabel: 'Terms of Service',
            policyLabel: 'Privacy Policy',
            infoLabel: 'Depix',
            fistMessage: 'By uploading an image you agree to our ',
            secondMessagePartOne: 'To learn more how ',
            secondMessagePartTwo: ' handles your personal data, check our ',
        },
        uploadSectionLabels: {
            placeholderMessage: 'Upload or drag an image here',
            allowedTypes: 'Supported formats jpeg, jpg, png, webp. Up to 8Mb file size. ',
            typeError: 'The uploaded file has wrong type. Supported formats jpeg, jpg, png, webp',
            sizeError: 'The uploaded file is too large. File size limit is up to 8Mb'
        },
        statusSectionLabels: {
            firstStepLabel: 'Uploading image.',
            secondStepLabel: 'Rendering.'
        },
        buttonLabels: {
            upload: 'Upload Image',
            cancel: 'Cancel',
            reset: 'Upload new image',
            moreInfo: 'Subscribe to Pro'
        },
        resultSectionLabels: {
            remainingLabel: 'free generations left',
            reachedLimitLabel: 'Reached the limit of free generations for this hour'
        },
    }
};
