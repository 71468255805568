import { createTheme } from '@mui/material';
import Components from './ThemeComponents';
import ThemeTypography from './ThemeTypography';

export const MainTheme = createTheme({
    palette: {
        primary: {
            main: '#87B4EA',
            light: '#424242',
            light1: '#686868',
            grey: '#373737',
            grey1: '#F1F1F1',
            dark: '#21232B',
            inactive: '#8e8e8e',
            inactive1: '#a0a0a1',
            contrastText: '#121213',
        }, 
        neutrals: {
            main: '#2B2B2B',
            light: '#686868',
            light1: '#323232',
            grey: '#424242',
            grey1: '#8e8e8e',
            dark: '#242424',
            inactive: '#F7F7F7',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#000000'
        },
        error: {
            main: '#fe8588',
        },
    },
    components: Components,
    typography: ThemeTypography,
  });