import React from 'react';
import { Box, styled, ImageList, ImageListItem, Typography  } from '@mui/material';
import { UseMainPageForm } from '../../context/MainPageFormContext/UseMainPageForm';
import { useMainPage } from '../../context/MainPageContext/UseMainPage';
import { Sections } from '../../types/enums';
import { AppLabels } from '../../utils/constants';

const Gallery = (): JSX.Element => {
  const {
    generationsHistory,
    setActiveImageUrl,
  } = UseMainPageForm();

  const { matches, setActiveSection } = useMainPage();

  const handleImageClick = (imageUrl: string): void => {
    setActiveImageUrl(imageUrl);
    setActiveSection(Sections.GenerationResult);
  };
  

  return (
    <Wrapper>
      { !generationsHistory.length &&
        <HistoryMessage variant="subtitle1">
          { AppLabels.gallery.emptyGalleryMessage}
        </HistoryMessage>
      }
      { matches ? 
        <CustomImageList cols={1} gap={20}>
          {
            generationsHistory.map((item) => (
              <ImageListItem key={item} onClick={() => handleImageClick(item)}>
                <ListImage
                  src={item}
                />
            </ImageListItem>
            ))
          }
        </CustomImageList>
        :
        <CustomImageList cols={3}>
          {
            generationsHistory.map((item) => (
              <ImageListItem key={item} onClick={() => handleImageClick(item)}>
                <ListImage
                  src={item}
                />
            </ImageListItem>
            ))
          }
        </CustomImageList>
      }
    </Wrapper>
  );
};

const Wrapper = styled(Box)(() => ({
  flexDirection: 'column',
  flexWrap: 'nowrap',
  overflow: 'auto',
  textAlign: 'center',
}));

const CustomImageList = styled(ImageList)(() => ({
  padding: '0px 20px 0px 20px'
}));

const ListImage = styled('img')(() => ({
  borderRadius: '10px',
  margin:'0 auto',
  objectFit:'contain',
  maxWidth: '100%',
  maxHeight: '100%'
}));

const  HistoryMessage = styled(Typography)(({ theme }) => ({
  padding: '20px',
  color: theme.palette.neutrals.light,
  alignItems: 'center'
}));

export default Gallery;