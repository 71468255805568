
export const getImageFile = async (imageSrc: string, fileName: string): Promise<File> => {
    const result = await fetch(imageSrc);
    const blob = await result.blob();
    const file = new File([blob], fileName, {
        type: 'image/png',
    });
    return file;
};

export const getBlobFromBase64String = (imageSrc: string): Blob => {
    const byteCharacters = atob(imageSrc.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray]);

    return blob;
};
