import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, styled } from '@mui/material';
import { UploadImageIcon } from '../../../../assets/icons/components/UploadImageIcon';
import { ApplyStyleIcon } from '../../../../assets/icons/components/ApplyStyleIcon';
import { useDemoPage } from '../../../../context/DemoPageContext/useDemoPage';
import { DemoPageStates } from '../../../../types/enums';
import { AppLabels } from '../../../../utils/constants';
import { PanelMainSection } from '../PanelMainSection';
import { PanelLabel } from '../PanelLabel';
import PrimaryButton from '../PrimaryButton';
import { DemoPageStatusPanelProps } from '../../../../types/propsTypes';

const DemoPageStatusPanel = ({ onCancel }: DemoPageStatusPanelProps): React.JSX.Element => {

    const { currentStep, imageUrl } = useDemoPage();
    const [stepNumber, setStepNumber] = useState<'1' | '2'>('1');
    const [stepLabel, setStepLabel] = useState<string>('');

    useEffect(() => {
        if (currentStep !== DemoPageStates.UPLOADING &&
            currentStep !== DemoPageStates.STYLING) {
            return;
        }

        if (currentStep === DemoPageStates.UPLOADING) {
            setStepLabel(AppLabels.demoPageLabels.statusSectionLabels.firstStepLabel);
            setStepNumber('1');
        } else {
            setStepLabel(AppLabels.demoPageLabels.statusSectionLabels.secondStepLabel);
            setStepNumber('2');
        }

    }, [currentStep]);
    

  return (
        <SectionWrapper>
            <PanelMainSection backgroundUrl={imageUrl ? imageUrl : ''}>
                { currentStep  === DemoPageStates.UPLOADING
                    ? <StyledSgvUploadIcon/>
                    : <StyledApplyStyleIcon/> 
                }
                <PanelLabel className={'main'}>
                    {`${stepLabel} step ${stepNumber}/2.`}
                </PanelLabel>
                <ProgressBarContainer>
                    <ProgressPanel/>
                </ProgressBarContainer>
                <PrimaryButton onClick={onCancel} variant="outlined">
                    {AppLabels.demoPageLabels.buttonLabels.cancel}
                </PrimaryButton>
            </PanelMainSection>
        </SectionWrapper>
    );
};

export default DemoPageStatusPanel;

const SectionWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: '20px'
}));

const ProgressBarContainer = styled(Box)(() => ({
    width: '206px',
    borderRadius: '6px'
}));

const ProgressPanel = styled(LinearProgress)(({ theme }) => ({
    display: 'flex',
    borderRadius: '20px',

    '& .MuiLinearProgress-bar': {
        borderRadius: '6px',
        background: theme.palette.primary.main,
        maxHeight: '4px'
    }
}));

const StyledSgvUploadIcon = styled(UploadImageIcon)(({ theme }) => ({
    width: '56px',
    height: '66px',
    color: theme.palette.primary.dark
}));

const StyledApplyStyleIcon = styled(ApplyStyleIcon)(({ theme }) => ({
    width: '56px',
    height: '57px',
    color: theme.palette.primary.dark
}));
