import React from 'react';
import { Box, styled } from '@mui/material';
import { useExtendedGeneration } from '../../../../context/ExtendedGenerationContext/UseExtendedGeneration';

const ExtendedGenerationResult = (): JSX.Element => {
  const { activeImageUrl } = useExtendedGeneration();
  return (
    <PageContainer>
      { activeImageUrl && 
        <>
          <ContentWrapper>
          <ImageWrapper>
            <Image src={activeImageUrl}/>
          </ImageWrapper>
          </ContentWrapper>
        </>
      }
    </PageContainer>
  );
};

export default ExtendedGenerationResult;

const PageContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  minHeight: '100%'
}));

const ContentWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100%',
  height: '100%',

  padding: '0 5px 0',
}));

const ImageWrapper = styled(Box)(() => ({
  position: 'relative',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'baseline',

  height: '100%',
  margin:'auto',

  '&:not(:hover) div': {
    display: 'none'
  }
}));

const Image = styled('img')(() => ({
  borderRadius: '10px',
  width: 'auto',
  height: 'min-content',

  maxHeight: '100%',
  maxWidth: '100%'
}));